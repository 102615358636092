import { Weekdays } from '@travelity/form';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import {
    ApplicationSettings,
    Country,
    DayOfWeek,
    GetUserResDto,
    MembershipStatus,
    Nationality,
    PersonNameData,
    TimezoneName,
    UpdateUserReqDto,
    VendibilitySettings,
    WeekData,
} from '../../requests';
import { User } from './user.types';
import { currencies } from '../product/product.types';

export const convertFullNameToNameDto = (fullName: string): PersonNameData => {
    const [first, ...rest] = fullName.split(' ');
    const last = rest.pop() || '';
    const middle = rest.join(' ') || undefined;

    return {
        first,
        middle,
        last,
    };
};

export const convertNameDtoToFullName = (name: PersonNameData): string => {
    const { first, middle, last } = name;

    return middle ? `${first} ${middle} ${last}` : `${first} ${last}`;
};

const convertDaysOfWeekToWeekDays = (daysOfWeek: DayOfWeek): Weekdays => {
    switch (daysOfWeek) {
        case DayOfWeek.MONDAY:
            return Weekdays.MONDAY;
        case DayOfWeek.TUESDAY:
            return Weekdays.TUESDAY;
        case DayOfWeek.WEDNESDAY:
            return Weekdays.WEDNESDAY;
        case DayOfWeek.THURSDAY:
            return Weekdays.THURSDAY;
        case DayOfWeek.FRIDAY:
            return Weekdays.FRIDAY;
        case DayOfWeek.SATURDAY:
            return Weekdays.SATURDAY;
        case DayOfWeek.SUNDAY:
        default:
            return Weekdays.SUNDAY;
    }
};

const dayOfWeek: Record<string, DayOfWeek> = {
    mon: DayOfWeek.MONDAY,
    tue: DayOfWeek.TUESDAY,
    wed: DayOfWeek.WEDNESDAY,
    thu: DayOfWeek.THURSDAY,
    fri: DayOfWeek.FRIDAY,
    sat: DayOfWeek.SATURDAY,
    sun: DayOfWeek.SUNDAY,
};

export const userDtoToUser = (userDto: GetUserResDto): User => ({
    id: userDto.id,
    fullName: convertNameDtoToFullName(userDto.name),
    languages: userDto.languages,
    occupation: userDto.occupation,
    currency: userDto.app_settings?.financials?.currency?.abbr,
    timeZone: userDto.app_settings?.tz_name,
    workload: !!userDto.app_settings?.vendibility?.workload,
    maxEvents:
        userDto.app_settings?.vendibility?.workload?.max_events_per_day ||
        undefined,
    maxEventsUnlimited:
        userDto.app_settings?.vendibility?.workload?.max_events_per_day ===
        10000,
    maxHours:
        userDto.app_settings?.vendibility?.workload?.max_booked_hours_per_day ||
        undefined,
    maxHoursFullDay:
        userDto.app_settings?.vendibility?.workload
            ?.max_booked_hours_per_day === 24,
    weekdays: userDto.app_settings?.vendibility?.workweek?.days.map(
        ({ day_of_week }) => convertDaysOfWeekToWeekDays(day_of_week)
    ),
    weekdayHours: userDto.app_settings?.vendibility?.workweek?.days.reduce(
        (obj, cur) => ({
            ...obj,
            [convertDaysOfWeekToWeekDays(cur.day_of_week)]:
                cur.available_hours.map(({ start, end }) => ({
                    start: start.hours * 60 + (start?.minutes || 0),
                    end: end.hours * 60 + (end?.minutes || 0),
                })),
        }),
        {}
    ),
    concurrency: !!userDto.app_settings?.vendibility?.concurrency,
    concurrencyValue: !!userDto.app_settings?.vendibility?.concurrency?.enabled,
    concurrencyAuto: !!userDto.app_settings?.vendibility?.concurrency?.auto,
    isComplete: !!userDto.app_settings?.vendibility?.concurrency,
    email: userDto.contact_details?.emails?.[0],
    emails: userDto.contact_details?.emails?.map(value => ({ value })),
    numbers: userDto.contact_details?.phone_numbers?.map(
        ({ number, calling_code }) => {
            return { value: `${calling_code} ${number}` };
        }
    ),
    birthDate: userDto.birth_details?.date
        ? format(new Date(userDto.birth_details.date * 1000), 'dd.MM.yyyy')
        : undefined,
    birthPlace: userDto.birth_details?.place?.name,
    nationality: userDto.birth_details?.nationality,
    passportN: userDto.passport?.number,
    issuedBy: userDto.passport?.authority,
    issuedAt: userDto.passport?.issued_at
        ? format(new Date(userDto.passport.issued_at * 1000), 'dd.MM.yyyy')
        : undefined,
    expiresAt: userDto.passport?.expires_at
        ? format(new Date(userDto.passport.expires_at * 1000), 'dd.MM.yyyy')
        : undefined,
    citizenship: userDto.passport?.citizenship,
    isEnterprise: !!userDto.memberships?.find(
        m => m.status === MembershipStatus.ACTIVE && m.isolated
    ),
    avatar: userDto.avatar?.url,
});

export const userWeekdaysToWorkweek = (
    user: Partial<User>
): WeekData | undefined =>
    user.weekdayHours
        ? {
              days: Object.keys(user.weekdayHours).map(key => ({
                  day_of_week: dayOfWeek[key] as DayOfWeek,
                  available_hours:
                      user.weekdayHours?.[key as Weekdays]?.map(
                          ({ start, end }) => ({
                              start: {
                                  hours: Math.floor(start / 60),
                                  minutes: start % 60,
                              },
                              end: {
                                  hours: Math.floor(end / 60),
                                  minutes: end % 60,
                              },
                          })
                      ) || [],
              })),
          }
        : undefined;

export const stringToDate = (str: string): Date => {
    const [d, m, y] = str.split('.');
    if (!d || !m || !y) return new Date();

    return new Date(parseInt(y, 10), parseInt(m, 10), parseInt(d, 10));
};

export const dateToUnix = (date: Date | string) =>
    typeof date === 'string'
        ? Math.round(stringToDate(date).getTime() / 1000)
        : Math.round(date.getTime() / 1000);

export const userToUserAppSettingsDto = (
    user: Partial<User>
): Partial<ApplicationSettings> => {
    const appSettings: Partial<ApplicationSettings> = {};
    const vendibility: VendibilitySettings = {};

    if (user.maxEventsUnlimited !== undefined || user.maxEvents !== undefined) {
        vendibility.workload = {
            max_events_per_day: user.maxEventsUnlimited
                ? 10000
                : user.maxEvents,
            max_booked_hours_per_day: user.maxHoursFullDay ? 24 : user.maxHours,
        };
    }
    if (
        user.concurrencyValue !== undefined ||
        user.concurrencyAuto !== undefined
    ) {
        vendibility.concurrency = {
            enabled: user.concurrencyValue,
            auto: user.concurrencyAuto,
        };
    }
    if (user.weekdayHours) {
        vendibility.workweek = userWeekdaysToWorkweek(user);
    }

    if (user.timeZone) {
        appSettings.tz_name = user.timeZone as TimezoneName;
    }

    if (user.currency) {
        appSettings.financials = {
            currency: {
                name:
                    currencies.find(({ abbr }) => abbr === user.currency)
                        ?.name || '',
                abbr: user.currency,
            },
        };
    }

    if (!isEmpty(vendibility)) {
        appSettings.vendibility = vendibility;
    }
    return appSettings;
};

export const userDetailsToUserDetailsDto = (
    user: Partial<User>
): UpdateUserReqDto => ({
    occupation: user.occupation,
    languages: user.languages,
    app_settings: userToUserAppSettingsDto(user),
    name: user.fullName ? convertFullNameToNameDto(user.fullName) : undefined,
    contact_details:
        user.emails || user.numbers
            ? {
                  emails: user.emails?.map(({ value }) => value),
                  phone_numbers: user.numbers
                      ?.map(({ value }) => value)
                      .map(number => ({
                          calling_code: parseInt(number.split(' ')[0], 10),
                          number: parseInt(number.split(' ')[1], 10),
                      })),
              }
            : undefined,
    birth_details: user.birthDate
        ? {
              nationality:
                  (user.nationality?.toLowerCase() as Nationality) || undefined,
              date: dateToUnix(user.birthDate),
              place: user.birthPlace
                  ? {
                        name: user.birthPlace,
                    }
                  : undefined,
          }
        : undefined,
    passport: user.passportN
        ? {
              number: user.passportN,
              citizenship:
                  (user.citizenship?.toLowerCase() as Country) || undefined,
              authority: user.issuedBy,
              issued_at: user.issuedAt ? dateToUnix(user.issuedAt) : undefined,
              expires_at: user.expiresAt
                  ? dateToUnix(user.expiresAt)
                  : undefined,
          }
        : undefined,
});
