import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from '@travelity/form';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
} from '@mui/material';
import { patchProductToAddProductDto, Product } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useUpdateProduct } from '@travelity/api/src/queries';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { UpdateProductResDto } from '@travelity/api/src/requests';
import { ProductDetailsForm } from '../../../components/product-details';
import detailsSchema from '../../../components/product-details/product-details.schema';
import { ProductRouteForm } from '../../../components/product-route-form';
import routeSchema from '../../../components/product-route-form/product-route-form.schema';
import { ProductCapacityForm } from '../../../components/product-capacity-form';
import capacitySchema from '../../../components/product-capacity-form/product-capacity-form.schema';
import { ProductScheduleForm } from '../../../components/product-schedule-form';
import scheduleSchema from '../../../components/product-schedule-form/product-schedule-form.schema';
import { ProductFinancingForm } from '../../../components/product-financing-form';
import financialSchema from '../../../components/product-financing-form/product-financing-form.schema';
import { ProductOptionForm } from '../../../components/product-option-form';
import optionsSchema from '../../../components/product-option-form/product-option-form.schema';
import AccordionButtons from './accordion-buttons';
import { useLayoutContext } from '../../../layouts/main-layout/main-layout';
import { ActivityHistory } from '../../../components/activity-history';

export interface ProductEditFormProps {
    product: Product;
    updateProduct: (id: string, product: UpdateProductResDto) => void;
}

const formNames = {
    productInfo: 'product details',
    route: 'route',
    capacity: 'capacity',
    schedule: 'schedule',
    financial: 'pricing and financials',
    options: 'options',
};

const ProductEditForm: React.FC<ProductEditFormProps> = ({
    product,
    updateProduct,
}) => {
    const location = useLocation();
    const [expanded, setExpanded] = useState<number | undefined>(
        location.state || 0
    );
    const { canEdit } = product.permissions;

    const [editing, setEditing] = useState(false);
    const toggleExpanded = (number: number) => {
        if (editing) return;
        setExpanded(s => (s === number ? undefined : number));
    };
    const onEdit = useCallback(() => setEditing(true), []);

    useEffect(() => {
        if (location.state && !editing) setExpanded(location.state);
    }, [location.state]);

    const { enqueueSnackbar } = useSnackbar();
    const { mutate: update, isLoading } = useUpdateProduct({
        onSuccess: () => {
            setEditing(false);
        },
        onError: (error: any) => {
            if (error?.status === 409) {
                enqueueSnackbar('Nothing to save', { variant: 'error' });
            } else if (error?.body) {
                enqueueSnackbar(error.body, { variant: 'error' });
            }
        },
    });
    const onSubmit = useCallback(
        (data: any, e?: React.BaseSyntheticEvent) => {
            e?.preventDefault();
            const key = e?.target.id;
            update(
                {
                    productId: product.id,
                    requestBody: patchProductToAddProductDto({ [key]: data }),
                },
                {
                    onSuccess: newProduct => {
                        updateProduct(product.id, newProduct);
                        enqueueSnackbar(
                            // @ts-ignore
                            `Updated ${formNames[key]} of the product "${product.productInfo.name}"`,
                            {
                                variant: 'success',
                            }
                        );
                    },
                }
            );
        },
        [product]
    );
    const {
        Form: DetailsForm,
        reset: resetDetails,
        formState: { errors: detailsErrors },
    } = useForm({
        defaultValues: product.productInfo,
        onSubmit,
        mode: 'onChange',
        schema: detailsSchema,
    });

    const {
        Form: RouteForm,
        reset: resetRoute,
        formState: { errors: routeErrors },
    } = useForm({
        defaultValues: product.route,
        onSubmit,
        mode: 'onChange',
        schema: routeSchema,
    });
    const {
        Form: CapacityForm,
        reset: resetCapacity,
        formState: { errors: capacityErrors },
    } = useForm({
        defaultValues: product.capacity,
        onSubmit,
        mode: 'onChange',
        schema: capacitySchema,
    });
    const {
        Form: ScheduleForm,
        reset: resetSchedule,
        formState: { errors: scheduleErrors },
    } = useForm({
        defaultValues: product.schedule,
        onSubmit,
        mode: 'onChange',
        schema: scheduleSchema,
    });
    const {
        Form: FinancialInformationForm,
        reset: resetFinancial,
        formState: { errors: financialErrors },
    } = useForm({
        defaultValues: product.financial,
        onSubmit,
        mode: 'onChange',
        context: {
            capacities: product.capacity?.capacities,
        },
        schema: financialSchema,
    });
    const {
        Form: OptionsForm,
        reset: resetOptions,
        formState: { errors: optionsErrors },
    } = useForm({
        defaultValues: product.options,
        onSubmit,
        mode: 'onChange',
        schema: optionsSchema,
    });

    const reset = useCallback(() => {
        resetDetails(product.productInfo);
        resetRoute(product.route);
        resetCapacity(product.capacity);
        resetSchedule(product.schedule);
        resetFinancial(product.financial);
        resetOptions(product.options);
        // switch (expanded) {
        //     case 0:
        //         resetDetails(product.productInfo);
        //         break;
        //     case 1:
        //         resetRoute(product.route);
        //         break;
        //     case 2:
        //         resetCapacity(product.capacity);
        //         break;
        //     case 3:
        //         resetSchedule(product.schedule);
        //         break;
        //     case 4:
        //         resetFinancial(product.financial);
        //         break;
        //     case 5:
        //         resetOptions(product.options);
        //         break;
        //     default:
        // }
    }, [expanded, product]);

    const onCancel = useCallback(() => {
        reset();
        setEditing(false);
    }, [reset]);

    useEffect(() => {
        reset();
        setEditing(false);
    }, [product]);

    const { setMainIsLoading } = useLayoutContext();
    useEffect(() => {
        setMainIsLoading(isLoading);
    }, [isLoading]);

    return (
        <Box
            sx={{
                height: 1,
                '& > div:last-child': {
                    height: 'calc(100% - 38px)',
                },
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '8px 18px',
                        marginBottom: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '0px 0px 12px 0px',
                        background: '#6B748C',
                        color: '#FFF',
                        fontFamily: 'Lexend',
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '100%',
                        textTransform: 'capitalize',
                    }}
                >
                    Product Preview
                </Box>
            </Stack>
            <PerfectScrollbar>
                <Box
                    sx={{
                        '&&& .MuiAccordionSummary-root': {
                            borderBottom: '1px solid #DFE1ED',
                        },
                        '&&& .MuiAccordionDetails-root': {
                            py: 3,
                        },
                    }}
                >
                    <DetailsForm id="productInfo">
                        <Accordion
                            disableGutters
                            expanded={expanded === 0}
                            onChange={() => toggleExpanded(0)}
                        >
                            <AccordionSummary>
                                Product Details
                                {expanded === 0 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isLoading}
                                        errors={detailsErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductDetailsForm
                                    preview={!editing || expanded !== 0}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </DetailsForm>
                    <RouteForm id="route">
                        <Accordion
                            disableGutters
                            expanded={expanded === 1}
                            onChange={() => toggleExpanded(1)}
                        >
                            <AccordionSummary>
                                Route
                                {expanded === 1 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isLoading}
                                        errors={routeErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductRouteForm
                                    showFull
                                    preview={!editing || expanded !== 1}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </RouteForm>
                    <CapacityForm id="capacity">
                        <Accordion
                            disableGutters
                            expanded={expanded === 2}
                            onChange={() => toggleExpanded(2)}
                        >
                            <AccordionSummary>
                                Capacity
                                {expanded === 2 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isLoading}
                                        errors={capacityErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductCapacityForm
                                    preview={!editing || expanded !== 2}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </CapacityForm>
                    <ScheduleForm id="schedule">
                        <Accordion
                            disableGutters
                            expanded={expanded === 3}
                            onChange={() => toggleExpanded(3)}
                        >
                            <AccordionSummary>
                                Schedule
                                {expanded === 3 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isLoading}
                                        errors={scheduleErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductScheduleForm
                                    preview={!editing || expanded !== 3}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </ScheduleForm>
                    <FinancialInformationForm id="financial">
                        <Accordion
                            disableGutters
                            expanded={expanded === 4}
                            onChange={() => toggleExpanded(4)}
                        >
                            <AccordionSummary>
                                Pricing and Financials
                                {expanded === 4 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isLoading}
                                        errors={financialErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductFinancingForm
                                    preview={!editing || expanded !== 4}
                                    capacity={
                                        product.capacity as Record<string, any>
                                    }
                                />
                            </AccordionDetails>
                        </Accordion>
                    </FinancialInformationForm>
                    <OptionsForm id="options">
                        <Accordion
                            disableGutters
                            expanded={expanded === 5}
                            onChange={() => toggleExpanded(5)}
                        >
                            <AccordionSummary>
                                Product Options
                                {expanded === 5 && canEdit && (
                                    <AccordionButtons
                                        editing={editing}
                                        saving={isLoading}
                                        errors={optionsErrors}
                                        onEditCancel={onCancel}
                                        onEdit={onEdit}
                                    />
                                )}
                            </AccordionSummary>
                            <AccordionDetails>
                                <ProductOptionForm
                                    preview={!editing || expanded !== 5}
                                    currency={product.financial?.currency}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </OptionsForm>
                    <Accordion
                        disableGutters
                        expanded={expanded === 6}
                        onChange={() => toggleExpanded(6)}
                    >
                        <AccordionSummary>Activity Log</AccordionSummary>
                        <AccordionDetails>
                            <ActivityHistory
                                entity="product"
                                lifecycle={product.lifecycle}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </PerfectScrollbar>
        </Box>
    );
};

export default React.memo(ProductEditForm);
