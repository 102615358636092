/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateAssetReqMileageDto } from './CreateAssetReqMileageDto';
import type { CreateAssetReqOwnerDto } from './CreateAssetReqOwnerDto';
import type { UploadAvatarUsersReqRbacDto } from './UploadAvatarUsersReqRbacDto';
import type { VehicleFuelType } from './VehicleFuelType';

export type CreateAssetReqDto = {
    /**
     * RBAC of the resource
     */
    rbac?: UploadAvatarUsersReqRbacDto;
    /**
     * type of the asset
     */
    type: CreateAssetReqDto.type;
    /**
     * Asset owner data
     */
    owner?: CreateAssetReqOwnerDto;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    fuel_type: VehicleFuelType;
    /**
     * Vehicle mileage
     */
    mileage: CreateAssetReqMileageDto;
};

export namespace CreateAssetReqDto {

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
