import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    CardList,
    CreatableTags,
    PaxPicker,
    Select,
    TagsList,
    TextField,
    useForm,
} from '@travelity/form';
import { Box, Chip, Skeleton, Stack, Typography } from '@mui/material';
import {
    AvailableProduct,
    convertDurationDtoToDuration,
    DiscountType,
    ProductOptionType,
    AvailabilityFilterValues,
    useCreateBooking,
    Customer,
    AgeBands,
    AvailableProductOption,
    PricingType,
    AgebandName,
    RequestTypes,
    useUpdatePrice,
    SourceType,
    DirectSource,
    PersonNameData,
} from '@travelity/api';
import { useTranslation } from 'react-i18next';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import { format } from 'date-fns';
import Button from '@mui/material/Button';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { durationToHumanReadable } from '@travelity/utils';
import { Heading, LoadingOverlay, SquareCard, Tag } from '@travelity/ui';
import { ComputePriceBookingsResFinancialsPriceDto } from '@travelity/api/src/requests';
import { useSnackbar } from 'notistack';
import { useHelperContext } from '../../../contexts/helper-context';
import { ProductTypeIcon } from '../../../components/product-type-icon';
import { useIsChanged, useSelectOptions } from '../../../hooks';
import SelectCustomer from '../../../components/select-customer/select-customer';
import { PriceSummary } from '../../../components/price-summary';
import { FilterPaxValue } from '../../../components/filters/filters.types';
import { RouteTooltip } from '../../../components/route-tooltip/route-tooltip';
import schema from './booking.side-from.schema';
import { OptionModal } from '../../../components/option-modal';
import SelectReferral from '../../../components/select-company/select-referral';

export interface BookingSideFormProps {
    availableProduct: AvailableProduct;
    time: number;
    filters: AvailabilityFilterValues;
    changeTime: (v: number) => void;
    onSuccess: () => void;
    discard: () => void;
}

export interface BookingCreationForm {
    options: AvailableProductOption[];
    customer: Customer;
    discountType: DiscountType;
    discountAmount: number;
    time: number;
    pax: FilterPaxValue;
    notes: { value: string }[];
    sourceType: SourceType;
    sourceName?: DirectSource;
    referral?: {
        id?: string;
        name?: PersonNameData;
        email: string;
        company?: string;
    };
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

const sourceTypes = [
    {
        value: SourceType.DIRECT,
        label: 'Direct',
    },
    {
        value: SourceType.REFERRAL,
        label: 'Referral',
    },
];

const directSources = [
    {
        value: DirectSource.EMAIL,
        label: 'Email',
    },
    {
        value: DirectSource.PHONE_CALL,
        label: 'Phone Call',
    },
    {
        value: DirectSource.WALK_IN,
        label: 'Walk-in',
    },
];

const BookingSideForm: React.FC<BookingSideFormProps> = props => {
    const { t } = useTranslation('product');
    const { enqueueSnackbar } = useSnackbar();
    const { availableProduct, time, filters, discard, onSuccess, changeTime } =
        props;
    const { mutate: createBooking, isLoading: isSaving } = useCreateBooking(
        {
            onSuccess: () => {
                onSuccess();
                enqueueSnackbar(
                    `Created a booking for the product "${availableProduct.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
            onError: (e, params) => {
                let message = `Failed to create the booking for the product "${availableProduct.name}"`;
                if ((e as Error)?.message === RequestTypes.ORDER) {
                    message = `Failed to create the order for the customer "${params.customer.fullName}"`;
                }
                if ((e as Error)?.message === RequestTypes.EVENT) {
                    message = `Failed to create the event for the product "${availableProduct.name}"`;
                }
                enqueueSnackbar(message, {
                    variant: 'error',
                });
            },
        },
        {
            onOrderSuccess: (order, params) => {
                enqueueSnackbar(
                    `Created an order #${order.number} for the customer "${params.customer.fullName}"`,
                    {
                        variant: 'success',
                    }
                );
            },
            onEventSuccess: () => {
                enqueueSnackbar(
                    `Created an event for the product "${availableProduct.name}"`,
                    {
                        variant: 'success',
                    }
                );
            },
        }
    );

    const selectedEvent = useMemo(() => {
        return availableProduct.events.find(
            ({ time: timestamp }) => timestamp === time
        );
    }, [availableProduct, time]);

    const [financialPrice, setFinancialPrice] =
        useState<ComputePriceBookingsResFinancialsPriceDto | null>(null);
    const {
        Form,
        watch,
        setValue,
        formState: { errors },
        trigger,
    } = useForm<BookingCreationForm>({
        schema,
        mode: 'onChange',
        validateInitially: true,
        defaultValues: {
            time,
            pax: filters.pax,
            discountType: DiscountType.RELATIVE,
            sourceType: SourceType.DIRECT,
        },
        onSubmit: useCallback(
            (data: BookingCreationForm, e?: React.BaseSyntheticEvent) => {
                if (!selectedEvent || !financialPrice) return;
                const productOptions =
                    data.options?.map(({ name, type, pax }) => ({
                        name,
                        type,
                        participants: { pax },
                    })) || [];
                createBooking({
                    customer: data.customer,
                    event: selectedEvent,
                    notes: data.notes?.map(({ value }) => value),
                    productOptions,
                    price: financialPrice,
                    // @ts-ignore
                    isDraft: e?.nativeEvent.submitter.dataset.draft,
                    source: {
                        type: data.sourceType,
                        name: data.sourceName,
                        referral: data.referral,
                    },
                });
            },
            [selectedEvent, financialPrice]
        ),
    });

    const sourceType = watch('sourceType');
    useEffect(() => {
        trigger('referral');
        trigger('sourceName');
    }, [sourceType]);

    const productChanged = useIsChanged(availableProduct.id);
    useEffect(() => {
        if (productChanged) {
            setValue('discountAmount', 0);
            setValue('options', []);
        }
    }, [productChanged, time, filters, DiscountType.RELATIVE]);

    const errorFields = Object.values(errors)
        .map(error => error?.message)
        .filter(v => v) as string[];

    const formTime = watch('time');
    useEffect(() => changeTime(formTime), [formTime]);
    useEffect(() => {
        if (time !== formTime) setValue('time', time);
    }, [time]);

    const stops = useMemo(() => {
        if (!availableProduct.route) return [];
        const list = [availableProduct.route.beginningLocation];

        availableProduct.route.stops.forEach(stop => {
            list.push(stop.name);
        });

        list.push(
            availableProduct.route.endingLocation ||
                availableProduct.route.beginningLocation
        );
        return list;
    }, [availableProduct]);

    const options = useSelectOptions(
        availableProduct.options,
        'name',
        'name',
        true
    );

    const availableTimeOptions = useMemo(
        () =>
            availableProduct.events.map(({ time: timestamp }) => ({
                value: timestamp,
                label: format(timestamp, 'HH:mm'),
            })) || [],
        [availableProduct]
    );

    const minPriceFormatted = `${numberWithSpaces(availableProduct.minPrice)} ${
        availableProduct.currency
    }`;
    const duration = selectedEvent?.rawDto?.date?.duration
        ? durationToHumanReadable(
              convertDurationDtoToDuration(selectedEvent.rawDto.date.duration)
          )
        : '';

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo(
            <>
                Product option is an item or a service that can be supplied as
                part of the product for an additional price or for free.
                <br />
                You give the freedom to your travelers to pick only options they
                need.
                <br />
                Travelity assumes that if the main product is available, then
                any product option is available with it.
            </>
        );
    }, [setInfo]);

    const prepaymentTypeOptions = useMemo(
        () => [
            { value: 'relative', label: '%' },
            {
                value: 'absolute',
                label: availableProduct.currency || 'Absolute',
            },
        ],
        [availableProduct.currency]
    );

    const selectedOptionsArray = watch('options');
    const selectedOptions =
        selectedOptionsArray && selectedOptionsArray.length
            ? selectedOptionsArray
            : undefined;
    const discountAmount = watch('discountAmount') || 0;
    const discountType = watch('discountType');
    const priceRequestParams = useMemo(() => {
        return {
            product: {
                id: availableProduct.id,
                financials: {
                    pricing:
                        selectedEvent?.rawDto.bookings_summary?.bookings[0]
                            ?.product.financials.pricing || {},
                },
                options: selectedOptions
                    ?.map(option =>
                        option
                            ? {
                                  name: option.name,
                                  type: option.type,
                                  participants: { pax: option.pax },
                                  financials: option.pricing
                                      ? {
                                            pricing: option.pricing,
                                        }
                                      : undefined,
                              }
                            : undefined
                    )
                    .filter(v => v) as {
                    name: string;
                    type: ProductOptionType;
                }[],
            },
            participants: {
                pax: filters.pax as FilterPaxValue,
            },
            financials: discountAmount
                ? {
                      price: {
                          discount: {
                              type: discountType,
                              amount: discountAmount,
                          },
                      },
                  }
                : undefined,
        };
    }, [
        discountAmount,
        discountType,
        time,
        availableProduct.id,
        selectedOptions,
    ]);

    const {
        data: price,
        rawData,
        isLoading: isPriceLoading,
        changed: priceChanged,
        update: updatePrice,
    } = useUpdatePrice(
        priceRequestParams,
        selectedEvent?.rawDto.bookings_summary?.bookings[0]?.financials.price
    );

    useEffect(() => {
        setFinancialPrice(rawData || null);
    }, [rawData]);

    const productTeamIds = useMemo(() => {
        return availableProduct.sharedTeams.map(({ id }) => id);
    }, [availableProduct]);

    // Handle option selection
    const [optionModal, setOptionModal] = useState<{
        item?: AvailableProductOption;
        callback: (item: AvailableProductOption) => void;
    } | null>(null);

    const onOptionAdd = useCallback(
        (callback: (item: AvailableProductOption) => void) => {
            setOptionModal({ callback });
        },
        []
    );

    const onOptionEdit = useCallback(
        (
            item: Record<string, any>,
            callback: (item: Record<string, any>) => void
        ) => {
            setOptionModal({ item: item as AvailableProductOption, callback });
        },
        []
    );

    const handleOptionSave = useCallback(
        (newItem: AvailableProductOption) => {
            optionModal?.callback(newItem);
            setOptionModal(null);
        },
        [optionModal]
    );

    const availableOptions = useMemo(() => {
        return options.filter(
            option =>
                optionModal?.item?.name === option.name ||
                !selectedOptions?.find(({ name }) => name === option.name)
        );
    }, [options, selectedOptions, optionModal]);

    const getOptionPrice = (n: string) => {
        const option = price?.options?.find(({ name }) => name === n);
        if (option) {
            const prices: { count: number; price: number; label: string }[] =
                [];
            if (option.price.type === PricingType.PER_TICKET) {
                prices.push({
                    count: option.price.count,
                    price: option.price.unitPrice,
                    label: '',
                });
            } else if (option.price.type === PricingType.PER_PRODUCT) {
                prices.push({
                    count: 1,
                    price: option.price.unitPrice,
                    label: '',
                });
            } else {
                Object.values(AgebandName).forEach((k: AgebandName) => {
                    // @ts-ignore
                    const p = option.price[k];
                    if (p) {
                        prices.push({
                            count: p.count,
                            price: p.unitPrice,
                            label: k,
                        });
                    }
                });
            }

            if (option.price.base) {
                prices.push({
                    count: 1,
                    price: option.price.base,
                    label: '',
                });
            }

            const totalPrice = prices.reduce(
                (s, p) => s + p.count * p.price,
                0
            );
            return `${numberWithSpaces(totalPrice)} ${price?.currency}`;
        }
        return 'Free';
    };

    const hasOptionPrice = (n: string) => {
        return options.find(o => o.name === n && o.hasPrice);
    };

    return (
        <>
            <Stack sx={{ pl: 2, pr: 0.5, height: 1 }}>
                <Stack
                    direction="row"
                    sx={{ pr: 1.5, pt: 1 }}
                    alignItems="center"
                    gap={1}
                >
                    <Heading
                        sx={{
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            pr: 1,
                        }}
                        ellipsis
                    >
                        {availableProduct.name}
                    </Heading>
                    <Chip
                        sx={{
                            color: '#fff',
                            bgcolor: '#3B4D7D',
                            px: '12px',
                            py: '6px',
                            '& .MuiChip-label': {
                                pl: 0.5,
                                pr: 0,
                            },
                        }}
                        icon={<ProductTypeIcon type={availableProduct.type} />}
                        label={t(availableProduct.type)}
                    />
                    {!!stops.length && (
                        <RouteTooltip stops={stops}>
                            <Chip
                                color="secondary"
                                sx={{
                                    color: '#36869C',
                                    bgcolor: '#DDF0F5',
                                    px: '12px',
                                    py: '6px',
                                    '& .MuiChip-label': {
                                        pl: 0.5,
                                        pr: 0,
                                    },
                                    '& .MuiChip-icon': {
                                        mx: 0,
                                    },
                                }}
                                icon={
                                    <DirectionsRoundedIcon
                                        sx={{
                                            fontSize: '12px',
                                            color: '#36869C',
                                        }}
                                    />
                                }
                                label="Route"
                            />
                        </RouteTooltip>
                    )}
                </Stack>

                <Stack
                    sx={{
                        grow: 2,
                        pl: 0,
                        pr: selectedEvent?.availableSeats ? 4 : 3,
                        pt: '10px',
                        pb: 2,
                        height: '139px',
                    }}
                    direction="row"
                    justifyContent="space-evenly"
                    gap={1}
                >
                    <SquareCard
                        title="Start Time"
                        value={format(time, 'HH:mm')}
                        Icon={AccessTimeIcon}
                        count={selectedEvent?.availableSeats ? 4 : 3}
                    />
                    <SquareCard
                        title="Duration"
                        value={duration}
                        Icon={AccessTimeIcon}
                        count={selectedEvent?.availableSeats ? 4 : 3}
                    />
                    {!!selectedEvent?.availableSeats && (
                        <SquareCard
                            title="Available"
                            value={`${selectedEvent?.availableSeats} seats`}
                            Icon={ConfirmationNumberIcon}
                            count={selectedEvent?.availableSeats ? 4 : 3}
                        />
                    )}
                    <SquareCard
                        title="Min Price"
                        value={minPriceFormatted}
                        Icon={PaymentsOutlinedIcon}
                        count={selectedEvent?.availableSeats ? 4 : 3}
                    />
                </Stack>
                <Form
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 2,
                        position: 'relative',
                        height: 'calc(100% - 144px)',
                    }}
                >
                    <Box
                        sx={{
                            minHeight: 0,
                            flexGrow: 2,
                        }}
                    >
                        <PerfectScrollbar>
                            <Stack gap={2} sx={{ py: 1, pr: 1.5 }}>
                                {!!options?.length && (
                                    <CardList
                                        renderHeader={({ item }) => (
                                            <Box component="span">
                                                {item.name} ({item.type})
                                            </Box>
                                        )}
                                        renderContent={({ item }) => (
                                            <Stack direction="row">
                                                <Stack
                                                    direction="row"
                                                    gap={1}
                                                    px={1}
                                                    py={1}
                                                    flexWrap="wrap"
                                                    flexGrow={2}
                                                >
                                                    {Object.values(
                                                        AgeBands
                                                    ).map(
                                                        name =>
                                                            !!item.pax[
                                                                name
                                                            ] && (
                                                                <Tag
                                                                    label={t(
                                                                        name
                                                                    )}
                                                                    values={[
                                                                        item
                                                                            .pax[
                                                                            name
                                                                        ],
                                                                    ]}
                                                                />
                                                            )
                                                    )}
                                                </Stack>
                                                <Stack pr={1}>
                                                    {!hasOptionPrice(
                                                        item.name
                                                    ) && (
                                                        <Chip
                                                            size="small"
                                                            label="Free"
                                                            sx={{
                                                                mt: 1,
                                                                color: 'success.main',
                                                                border: '1px solid',
                                                                borderColor:
                                                                    'success.main',
                                                                fontWeight: 400,
                                                                fontSize:
                                                                    '12px',
                                                                '& > .MuiChip-label':
                                                                    {
                                                                        px: '6px',
                                                                        py: '4px',
                                                                    },
                                                                bgcolor:
                                                                    'rgba(44, 172, 96, 0.20)',
                                                            }}
                                                        />
                                                    )}
                                                    {hasOptionPrice(
                                                        item.name
                                                    ) &&
                                                        (isPriceLoading ? (
                                                            <Skeleton
                                                                width={60}
                                                                sx={{
                                                                    fontSize:
                                                                        '32px',
                                                                }}
                                                            />
                                                        ) : (
                                                            <Chip
                                                                size="small"
                                                                label={getOptionPrice(
                                                                    item.name
                                                                )}
                                                                sx={{
                                                                    mt: 1,
                                                                    color: 'secondary.main',
                                                                    border: '1px solid',
                                                                    borderColor:
                                                                        'secondary.main',
                                                                    fontWeight: 400,
                                                                    fontSize:
                                                                        '12px',
                                                                    '& > .MuiChip-label':
                                                                        {
                                                                            px: '6px',
                                                                            py: '4px',
                                                                        },
                                                                    bgcolor:
                                                                        'rgba(85, 181, 207, 0.2)',
                                                                }}
                                                            />
                                                        ))}
                                                </Stack>
                                            </Stack>
                                        )}
                                        disableAdding={
                                            options.length <=
                                            (selectedOptions?.length || 0)
                                        }
                                        customAdd={onOptionAdd}
                                        customEdit={onOptionEdit}
                                        noEmptyState
                                        name="options"
                                        maxHeight={178}
                                        addButtonText="Add Product Option"
                                    />
                                )}
                                <Box>
                                    <Typography
                                        sx={{
                                            color: '#2B395B',
                                            fontSize: '12px',
                                        }}
                                    >
                                        Select Available Time
                                    </Typography>
                                    <TagsList
                                        name="time"
                                        options={availableTimeOptions}
                                        gap={1}
                                        maxItems={15}
                                        tagSx={{
                                            bgcolor: '#DDF0F5',
                                            color: '#2B395B',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            p: '10px 16px',
                                            '&.MuiChip-colorPrimary': {
                                                bgcolor: '#55B5CF',
                                                color: '#FFFFFF',
                                                fontWeight: 400,
                                            },
                                            '&:hover': {
                                                bgcolor: '#AAD9E7',
                                            },
                                        }}
                                    />
                                </Box>
                                <Select
                                    name="sourceType"
                                    label={t('Booking Source Type')}
                                    placeholder={t('Select Source Type')}
                                    options={sourceTypes}
                                />
                                {watch('sourceType') === SourceType.DIRECT ? (
                                    <Select
                                        name="sourceName"
                                        label={t('Booking Source')}
                                        placeholder={t('Select Source')}
                                        options={directSources}
                                    />
                                ) : (
                                    <SelectReferral
                                        name="referral"
                                        label="Booking Source"
                                        placeholder="Type company, email or name"
                                        teamIds={productTeamIds}
                                    />
                                )}
                                <PaxPicker
                                    name="pax"
                                    withDropdown
                                    selectProps={{
                                        disabled: true,
                                        width: '100%',
                                        label: 'PAX',
                                        placeholder: 'Select PAX',
                                    }}
                                />
                                <SelectCustomer
                                    name="customer"
                                    label="Customer Name or Email"
                                    placeholder="Type Customer Name or Email"
                                />
                                <Stack
                                    direction="row"
                                    gap={1}
                                    sx={{
                                        bgcolor: '#FFFFFF',
                                        borderRadius: '8px',
                                    }}
                                >
                                    <TextField
                                        label="Discount"
                                        placeholder="Type Discount"
                                        name="discountAmount"
                                        sx={{ flexGrow: 2 }}
                                    />
                                    <Select
                                        width="140px"
                                        name="discountType"
                                        label=""
                                        options={prepaymentTypeOptions}
                                    />
                                </Stack>
                                <CreatableTags
                                    name="notes"
                                    inputProps={{
                                        label: 'Notes',
                                        placeholder:
                                            'Type and press enter to add note',
                                    }}
                                    chipDirection="column"
                                    chipSx={{
                                        borderRadius: '12px',
                                        padding: '8px',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: '10px',
                                        bgcolor: '#F4F6FA',
                                        '& > .MuiChip-label': {
                                            color: '#2B395B',
                                            fontSize: '12px',
                                            whiteSpace: 'wrap',
                                        },
                                    }}
                                />
                            </Stack>
                        </PerfectScrollbar>
                    </Box>
                    <Stack
                        sx={{
                            width: 1,
                            zIndex: 1,
                            pr: 1.5,
                            pb: 2,
                            maxHeight: 1,
                        }}
                    >
                        <PriceSummary
                            price={price}
                            isLoading={isPriceLoading}
                            priceChanged={priceChanged}
                            updatePrice={updatePrice}
                        />
                        <Stack direction="row" mt={2} gap={1}>
                            <Button
                                variant="contained"
                                color="neutral"
                                sx={{ flexGrow: 1 }}
                                onClick={discard}
                            >
                                Discard
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ flexGrow: 2 }}
                                type="submit"
                                data-draft
                                disabled={
                                    !!errorFields.length ||
                                    isPriceLoading ||
                                    priceChanged
                                }
                            >
                                Save as Draft
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{ flexGrow: 1 }}
                                disabled={
                                    !!errorFields.length ||
                                    isPriceLoading ||
                                    priceChanged
                                }
                                type="submit"
                            >
                                Confirm
                            </Button>
                        </Stack>
                    </Stack>
                </Form>
            </Stack>
            <OptionModal
                handleCancel={() => setOptionModal(null)}
                handleConfirm={handleOptionSave}
                open={!!optionModal}
                options={availableOptions}
                item={optionModal?.item}
            />
            {isSaving && (
                <LoadingOverlay
                    title="Please wait..."
                    subTitle="It’ll just take a moment."
                />
            )}
        </>
    );
};

export default React.memo(BookingSideForm);
