import React, { useMemo } from 'react';
import { Select, SelectProps } from '@travelity/form';
import { TimezoneName } from '@travelity/api/src/requests';

export interface SelectTimezoneProps extends Omit<SelectProps, 'options'> {}

const SelectTimezone: React.FC<SelectTimezoneProps> = props => {
    const timezones = useMemo(() => {
        return Object.entries(TimezoneName).map(([, value]) => ({
            label: value,
            value,
        }));
    }, []);

    return <Select {...props} options={timezones} />;
};

export default SelectTimezone;
