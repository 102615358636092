import { GetAssetsResItemDto } from '../../requests';
import { Asset, EventAsset } from './asset.types';
import type = GetAssetsResItemDto.type;

export const getAssetDtoToAsset = (
    asset: Omit<GetAssetsResItemDto, 'created' | 'owner' | 'usage' | 'mileage'>
): EventAsset => ({
    id: asset.id,
    uuid: asset.id,
    licensePlate: asset.license_plate,
    make: asset.make,
    model: asset.model,
    year: asset.year,
    maxPassengers: asset.max_passengers,
    fuelType: asset.fuel_type,
});

export const assetToAssetDto = (
    asset: Asset
): Omit<GetAssetsResItemDto, 'created' | 'owner' | 'usage'> => ({
    id: asset.id,
    license_plate: asset.licensePlate,
    make: asset.make,
    model: asset.model,
    year: asset.year,
    type: type.VEHICLE,
    mileage: {
        start: asset.mileageStart || 0,
        end: asset.mileageEnd || 0,
    },
    max_passengers: asset.maxPassengers,
    fuel_type: asset.fuelType,
});
