import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    Box,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import _ from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { CheckedIcon, UncheckedIcon } from '@travelity/ui';

export interface MultiSelectProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    showErrors?: boolean;
    options: { value: string; label: string }[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({
    width,
    size,
    options,
    name,
    required,
    label,
    placeholder,
    showErrors,
    disabled,
    ...selectProps
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const optionsMap: Record<string, any> = options.reduce(
        (obj, cur) => ({
            ...obj,
            [cur.value]: cur,
        }),
        {}
    );

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    return (
        <FormControl
            sx={{ width: width || '388px' }}
            error={!!error}
            size={size}
            disabled={disabled}
        >
            <Controller
                render={({ field }: { field: Record<string, any> }) => (
                    <MuiSelect
                        required={!!required}
                        inputProps={{ required: false }}
                        label={label}
                        displayEmpty
                        multiple
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                            ...(selectProps.sx ? selectProps.sx : {}),
                            '&&& legend': placeholder
                                ? {
                                      maxWidth: '100%',
                                  }
                                : {},
                        }}
                        renderValue={selected => {
                            if (selected.length === 0 && placeholder) {
                                return (
                                    <Box
                                        component="span"
                                        sx={{
                                            color: '#949BAC',
                                        }}
                                    >
                                        {placeholder}
                                    </Box>
                                );
                            }

                            return selected
                                .map((v: string) => optionsMap[v]?.label || v)
                                .join(', ');
                        }}
                        disabled={disabled}
                        {...selectProps}
                        {...field}
                        value={field.value || []}
                        MenuProps={{ PaperProps: { sx: { maxHeight: 180 } } }}
                    >
                        {options.map(option => (
                            <MenuItem
                                sx={{
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&.Mui-focusVisible': {
                                        backgroundColor: 'transparent',
                                    },
                                    '&&&.Mui-selected': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                                key={option.value}
                                value={option.value}
                                disableRipple
                            >
                                <Checkbox
                                    checkedIcon={<CheckedIcon />}
                                    icon={<UncheckedIcon />}
                                    checked={
                                        field.value?.indexOf(option.value) > -1
                                    }
                                />
                                <ListItemText primary={option.label} />
                            </MenuItem>
                        ))}
                    </MuiSelect>
                )}
                name={name}
                control={control}
            />
            <InputLabel required={!!required} shrink>
                {label}
            </InputLabel>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default MultiSelect;
