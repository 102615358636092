/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PaymentMethodData {
    CASH = 'cash',
    POS_TERMINAL = 'pos_terminal',
    WIRE_TRANSFER = 'wire_transfer',
    PROMO = 'promo',
    GIFT_CARD = 'gift_card',
    PARTNER = 'partner',
    OTA = 'ota',
    WEBSITE = 'website',
}
