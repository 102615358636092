import { useMemo } from 'react';
import { UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import {
    useGetUser,
    useGetUserKey,
    useUpdateUser as useUpdateUser1,
    useUploadAvatarUsers,
} from '../../queries';
import { User } from './user.types';
import { userDtoToUser } from './user.converters';
import {
    UpdateUserReqDto,
    UploadAvatarUsersReqDto,
    UsersService,
} from '../../requests';

export const useUser = () => {
    const { data: userDto, ...rest } = useGetUser(undefined, {
        retry: false,
        cacheTime: 10,
    });

    const data: User | undefined = useMemo(() => {
        if (!userDto) {
            return undefined;
        }

        return userDtoToUser(userDto);
    }, [userDto?.last_updated?.at]);

    return {
        data,
        ...rest,
    };
};

export const useUpdateUser = (
    options: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateUser>>,
            unknown,
            {
                requestBody: UpdateUserReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();

    return useUpdateUser1({
        ...options,
        onSuccess: (...args) => {
            queryClient.setQueryData([useGetUserKey], args[0]);
            options.onSuccess?.(...args);
        },
    });
};

export const useUploadAvatarUser = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.uploadAvatarUsers>>,
            unknown,
            {
                requestBody: UploadAvatarUsersReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();

    return useUploadAvatarUsers({
        ...options,
        onSuccess: (...args) => {
            queryClient.setQueryData([useGetUserKey], args[0]);
            options?.onSuccess?.(...args);
        },
    });
};
