import React, { useCallback, useEffect, useMemo } from 'react';
import { Box, Divider, InputAdornment, Stack, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    MultiSelect,
    Select,
    Switch,
    TextField,
    useForm,
    WeekdayPicker,
    Weekdays,
} from '@travelity/form';
import {
    convertFullNameToNameDto,
    currencies,
    Language,
    User,
    userDetailsToUserDetailsDto,
    userWeekdaysToWorkweek,
} from '@travelity/api';
import { convertEnumToList } from '@travelity/utils';
import { useRegisterUsers, useUpdateUser } from '@travelity/api/src/queries';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import { TimezoneName } from '@travelity/api/src/requests';
import { getLastCompletedStep } from './register-steps.utils';
import OccupationSelect from './components/occupation-select';
import WeekdayRow from './components/weekday-row';
import { PersonalInfo } from './components/personal-info';
import SelectTimezone from '../../components/select-timezone/select-timezone';
import { useCurrencyOptions } from '../../hooks';

export interface RegisterStepsProps {
    user?: User;
}

export const RegisterSteps: React.FC<RegisterStepsProps> = props => {
    const { user } = props;
    const { enqueueSnackbar } = useSnackbar();
    const { user: auth0User } = useAuth0();
    const [currentStep, setCurrentStep] = React.useState(
        getLastCompletedStep(user) + 1
    );
    const [lastStep, setLastStep] = React.useState(
        getLastCompletedStep(user) + 1
    );

    const languages = useMemo(() => convertEnumToList<Language>(Language), []);
    const { mutate: create } = useRegisterUsers({
        onSuccess: () => {
            setCurrentStep(currentStep + 1);
            setLastStep(currentStep + 1);
        },
        onError: () => {
            enqueueSnackbar('Failed to register', {
                variant: 'error',
            });
        },
    });

    const navigate = useNavigate();
    const { mutate: update } = useUpdateUser({
        onSuccess: () => {
            if (currentStep === 9) {
                navigate('/products');
                navigate(0);
            } else {
                setCurrentStep(currentStep + 1);
                setLastStep(currentStep + 1);
            }
        },
        onError: err => {
            // @ts-ignore
            if (err?.body.message.endsWith('has no updated fields')) {
                if (currentStep === 9) {
                    navigate(0);
                } else {
                    setCurrentStep(currentStep + 1);
                    setLastStep(currentStep + 1);
                }
            }
            enqueueSnackbar('Failed to update user', {
                variant: 'error',
            });
        },
    });

    const onBack = useCallback(() => {
        setCurrentStep(prev => prev - 1);
    }, []);

    const { Form, getValues, setValue, watch } = useForm({
        onSubmit: useCallback(
            (data: Partial<User>) => {
                if (currentStep === 1) {
                    if (auth0User?.email) {
                        if (lastStep === 1) {
                            create({
                                requestBody: {
                                    contact_details: {
                                        emails: [auth0User.email],
                                    },
                                    name: convertFullNameToNameDto(
                                        data.fullName as string
                                    ),
                                },
                            });
                        } else {
                            update({
                                requestBody: {
                                    name: convertFullNameToNameDto(
                                        data.fullName as string
                                    ),
                                },
                            });
                        }
                    }
                } else {
                    update({
                        requestBody:
                            currentStep === 2
                                ? {
                                      languages: data.languages,
                                  }
                                : currentStep === 3
                                ? {
                                      app_settings: {
                                          financials: {
                                              currency: {
                                                  name:
                                                      currencies.find(
                                                          ({ abbr }) =>
                                                              abbr ===
                                                              data.currency
                                                      )?.name || '',
                                                  abbr: data.currency as string,
                                              },
                                          },
                                      },
                                  }
                                : currentStep === 4
                                ? {
                                      app_settings: {
                                          tz_name:
                                              data.timeZone as TimezoneName,
                                      },
                                  }
                                : currentStep === 5
                                ? {
                                      occupation: data.occupation,
                                  }
                                : currentStep === 6
                                ? {
                                      app_settings: {
                                          vendibility: {
                                              workload: {
                                                  max_events_per_day:
                                                      data.maxEventsUnlimited
                                                          ? 10000
                                                          : data.maxEvents,
                                                  max_booked_hours_per_day:
                                                      data.maxHoursFullDay
                                                          ? 24
                                                          : data.maxHours,
                                              },
                                          },
                                      },
                                  }
                                : currentStep === 7
                                ? {
                                      app_settings: {
                                          vendibility: {
                                              workweek:
                                                  userWeekdaysToWorkweek(data),
                                          },
                                      },
                                  }
                                : currentStep === 8
                                ? {
                                      app_settings: {
                                          vendibility: {
                                              concurrency: {
                                                  enabled:
                                                      data.concurrencyValue,
                                                  auto: data.concurrencyAuto,
                                              },
                                          },
                                      },
                                  }
                                : userDetailsToUserDetailsDto(data),
                    });
                }
            },
            [currentStep, auth0User]
        ),
        defaultValues: user || {},
        mode: 'onChange',
        validateInitially: true,
    });

    useEffect(() => {
        if (auth0User) {
            const userForm = getValues();
            if (!userForm.emails?.length) {
                setValue('emails', [{ value: auth0User.email as string }]);
            }
        }
    }, [auth0User]);

    const weekdays = watch('weekdays');
    const weekdayHours = watch('weekdayHours');
    useEffect(() => {
        if (weekdays) {
            const values = getValues();
            const currentWeekdayHours = values.weekdayHours;
            const weekdayHoursNew: Partial<{
                [v in Weekdays]: { start: number; end: number }[];
            }> = {};

            weekdays.forEach(w => {
                weekdayHoursNew[w] = currentWeekdayHours?.[w] || [
                    {
                        start: 0,
                        end: 1439,
                    },
                ];
            });

            setValue('weekdayHours', weekdayHoursNew);
        }
    }, [weekdays]);

    const currencyOptions = useCurrencyOptions();

    return (
        <Box
            sx={{
                bgcolor: '#fff',
                mt: 2,
                height: 'calc(100% - 16px)',
                '> form': { height: 1 },
            }}
        >
            <PerfectScrollbar>
                <Form>
                    <Stack
                        sx={{
                            py: 5,
                            height: 1,
                            minHeight: 'calc(100vh - 84px)',
                        }}
                        gap={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        {currentStep === 1 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What is your full name?
                                </Typography>
                                <TextField
                                    name="fullName"
                                    label=""
                                    placeholder="Type Full Name"
                                />
                                <Stack direction="row" spacing={1}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('fullName')?.match(
                                                /\s[a-zA-Z]/
                                            )
                                                ? ['Full Name']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 2 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What languages you speak?
                                </Typography>
                                <MultiSelect
                                    placeholder="Select Language"
                                    name="languages"
                                    label="Languages"
                                    options={languages}
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('languages')?.length
                                                ? ['Languages']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 3 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What is your base currency?
                                </Typography>
                                <Select
                                    placeholder="Select base currency"
                                    name="currency"
                                    label="Currency"
                                    options={currencyOptions}
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('currency')?.length
                                                ? ['Currency']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 4 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Which time zone you are in?
                                </Typography>
                                <SelectTimezone
                                    name="timeZone"
                                    label=""
                                    placeholder="Select time zone"
                                />
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('timeZone')?.length
                                                ? ['Time Zone']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 5 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    What do you do?
                                </Typography>
                                <Stack>
                                    <OccupationSelect name="occupation" />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('occupation')
                                                ? ['Occupation']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 6 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Workload Settings
                                </Typography>
                                <Box sx={{ width: '388px' }}>
                                    <Divider sx={{ my: 3 }}>Max Events</Divider>
                                    <Box sx={{ px: 1 }}>
                                        <Switch
                                            name="maxEventsUnlimited"
                                            label="Unlimited"
                                            LabelProps={{
                                                sx: {
                                                    color: '#2B395B',
                                                    pl: 1,
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="maxEvents"
                                            label=""
                                            placeholder=""
                                            sx={{ my: 2 }}
                                            disabled={watch(
                                                'maxEventsUnlimited'
                                            )}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Box
                                                            sx={{
                                                                color: '#2B395B',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            EVENTS/DAY
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            regExp={/^(\d+)?$/}
                                        />
                                    </Box>

                                    <Divider sx={{ my: 3 }}>
                                        Max Booked Hours
                                    </Divider>
                                    <Box sx={{ px: 1 }}>
                                        <Switch
                                            name="maxHoursFullDay"
                                            label="Full Day"
                                            LabelProps={{
                                                sx: {
                                                    color: '#2B395B',
                                                    pl: 1,
                                                },
                                            }}
                                        />
                                        <TextField
                                            name="maxHours"
                                            label=""
                                            placeholder=""
                                            sx={{ my: 2 }}
                                            disabled={watch('maxHoursFullDay')}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Box
                                                            sx={{
                                                                color: '#2B395B',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            HOURS/DAY
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            regExp={/^(\d+)?$/}
                                        />
                                    </Box>
                                </Box>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={[
                                            ...(!watch('maxEvents') &&
                                            !watch('maxEventsUnlimited')
                                                ? ['Max Events']
                                                : []),
                                            ...(!watch('maxHours') &&
                                            !watch('maxHoursFullDay')
                                                ? ['Max Booked Hours']
                                                : []),
                                        ]}
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 7 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Setup Your Workweek
                                </Typography>

                                <Box sx={{ width: '560px' }}>
                                    <WeekdayPicker
                                        name="weekdays"
                                        chipSx={{
                                            py: '12px',
                                            px: '16px',
                                            borderRadius: '26px',
                                            background: '#F4F6FA',
                                            border: '1px solid transparent',
                                            '&.MuiChip-colorPrimary': {
                                                border: '1px solid #55B5CF',
                                                background: '#DDF0F5',
                                                color: '#2B395B',
                                            },
                                        }}
                                    />
                                    <Divider sx={{ mx: 10, my: 3 }} />
                                    {weekdayHours &&
                                        Object.keys(weekdayHours).map(w => (
                                            <WeekdayRow
                                                key={w}
                                                name={`weekdayHours.${w}`}
                                                weekday={w as Weekdays}
                                            />
                                        ))}
                                </Box>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{ width: '388px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                        requiredFields={
                                            !watch('weekdays')?.length
                                                ? ['Weekdays']
                                                : undefined
                                        }
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 8 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Vendibility Preferences
                                </Typography>

                                <Box
                                    sx={{
                                        width: '364px',
                                        borderRadius: '12px',
                                        background: '#FBFBFE',
                                        p: 3,
                                    }}
                                >
                                    <Switch
                                        name="concurrencyValue"
                                        label="Concurrency"
                                        LabelProps={{
                                            sx: {
                                                color: '#2B395B',
                                                pl: 1,
                                            },
                                        }}
                                    />
                                    <Switch
                                        name="concurrencyAuto"
                                        label="Auto"
                                        LabelProps={{
                                            sx: {
                                                color: '#2B395B',
                                                pl: 1,
                                            },
                                        }}
                                    />
                                </Box>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    sx={{
                                        width: '364px',
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color="neutral"
                                        sx={{ flexGrow: 1 }}
                                        onClick={onBack}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            px: 10,
                                            py: 1,
                                        }}
                                    >
                                        Continue
                                    </Button>
                                </Stack>
                            </>
                        )}
                        {currentStep === 9 && (
                            <>
                                <Typography
                                    sx={{ color: '#C0C4CE', fontSize: '24px' }}
                                >
                                    Personal Information
                                </Typography>
                                <PersonalInfo
                                    onSkip={() => {
                                        navigate('/products');
                                        navigate(0);
                                    }}
                                />
                            </>
                        )}
                    </Stack>
                </Form>
            </PerfectScrollbar>
        </Box>
    );
};
