/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateAssetResOwnerDto } from './CreateAssetResOwnerDto';
import type { CreateAssetResUsageItemDto } from './CreateAssetResUsageItemDto';
import type { CreateAssetResUsageItemMileageDto } from './CreateAssetResUsageItemMileageDto';
import type { CreateProductResRbacDto } from './CreateProductResRbacDto';
import type { RegisterUsersResCreatedDto } from './RegisterUsersResCreatedDto';
import type { VehicleFuelType } from './VehicleFuelType';

export type CreateAssetResDto = {
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: RegisterUsersResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<RegisterUsersResCreatedDto>;
    /**
     * RBAC of the resource
     */
    rbac: CreateProductResRbacDto;
    /**
     * type of the asset
     */
    type: CreateAssetResDto.type;
    /**
     * Asset owner data
     */
    owner?: CreateAssetResOwnerDto;
    /**
     * Usage history of the asset
     */
    usage?: Array<CreateAssetResUsageItemDto>;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    fuel_type: VehicleFuelType;
    /**
     * Vehicle mileage
     */
    mileage: CreateAssetResUsageItemMileageDto;
};

export namespace CreateAssetResDto {

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
