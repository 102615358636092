import React, { useMemo, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useTeams } from '@travelity/api';
import { TeamItem } from '../../components/team-item';

import { ReactComponent as PreviewSvg } from '../products/assets/preview.svg';
// import { ReactComponent as LoopSvg } from '../../assets/loop.svg';
import { TeamItemSkeleton } from '../../components/team-item-skeleton';
import { TeamModal } from '../../components/team-modal';
import MemberPreview from './components/member-preview';

function Teams() {
    // const { t } = useTranslation('team');
    const { teamId, memberId } = useParams();

    const { data: teams, isLoading, refetch } = useTeams();

    const navigate = useNavigate();
    const [teamOpen, setTeamOpen] = useState(false);

    const team = useMemo(() => {
        if (!teams || !teamId) return undefined;
        return teams.find(t => teamId === t.id) || undefined;
    }, [teams, teamId, memberId]);

    const member = useMemo(() => {
        if (!team || !memberId) return undefined;
        return team.members.find(
            m => memberId === m.id || memberId === m.email
        );
    }, [team, memberId]);

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            <Box
                sx={{
                    bgcolor: '#FFF',
                    flexGrow: 2,
                    ml: 2,
                    my: 2,
                    px: 2,
                    height: 'calc(100% - 32px)',
                    minWidth: '0',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        py: 1.5,
                        height: '72px',
                    }}
                >
                    <Typography variant="h5" sx={{ color: '#C0C4CE' }}>
                        My Teams
                    </Typography>

                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setTeamOpen(true)}
                    >
                        Create Team
                    </Button>
                </Stack>
                {/* <Box> */}
                {/*    <Divider> */}
                {/*        <Filters /> */}
                {/*    </Divider> */}
                {/* </Box> */}
                {teams && (
                    <>
                        {teams.length > 1 && (
                            <Divider>{teams.length} teams found</Divider>
                        )}
                        {teams.length === 1 && <Divider>1 team found</Divider>}
                        {teams.length === 0 && (
                            <Divider>No teams found</Divider>
                        )}
                        <Box
                            sx={{
                                py: 1,
                                bgcolor: teams.length ? '#F8F9FA' : undefined,
                                height: 'calc(100% - 72px - 21px)',
                                '& > div': {
                                    py: 1,
                                },
                            }}
                        >
                            <PerfectScrollbar>
                                {teams.length ? (
                                    <Stack
                                        sx={{
                                            px: 2,
                                        }}
                                        gap={1}
                                    >
                                        {teams.map(t => (
                                            <TeamItem
                                                key={t.id}
                                                team={t}
                                                refetch={refetch}
                                                isSelected={t.id === teamId}
                                            />
                                        ))}
                                    </Stack>
                                ) : (
                                    <Stack
                                        sx={{
                                            display: 'flex',
                                            height: 1,
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Typography sx={{ color: '#949BAC' }}>
                                            Filter results will be here
                                        </Typography>
                                    </Stack>
                                )}
                            </PerfectScrollbar>
                        </Box>
                    </>
                )}
                {isLoading && (
                    <>
                        <Divider>
                            <Skeleton width={100} />
                        </Divider>

                        <Box
                            sx={{
                                py: 2,
                                bgcolor: '#F8F9FA',
                                height: 'calc(100% - 72px - 21px)',
                            }}
                        >
                            <Stack
                                sx={{
                                    px: 2,
                                }}
                                gap={1}
                            >
                                {[0, 1, 2].map(v => (
                                    <TeamItemSkeleton key={v} />
                                ))}
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
            <Box
                sx={{
                    bgcolor: '#FFF',
                    my: 2,
                    ml: 2,
                    width: '483px',
                    minWidth: '483px',
                    height: 'calc(100% - 32px)',
                    ...(!teamId
                        ? {
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: 2,
                          }
                        : {}),
                }}
            >
                {!teamId && !memberId && (
                    <>
                        <PreviewSvg />
                        <Typography sx={{ color: '#949BAC' }}>
                            No user is selected for preview
                        </Typography>
                    </>
                )}
                {member && team && (
                    <MemberPreview
                        member={member}
                        teamId={team.id as string}
                        refetch={refetch}
                        isEditable={!member.id && !!team?.permissions.write}
                    />
                )}
            </Box>
            {teamOpen && (
                <TeamModal
                    handleCancel={t => {
                        setTeamOpen(false);
                        if (t?.isolated) {
                            navigate(0);
                        } else {
                            refetch();
                        }
                    }}
                    open={teamOpen}
                />
            )}
        </Stack>
    );
}

export default Teams;
