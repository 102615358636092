/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AddAssetEventsResOperationsAssetsItemAssigneeDto } from './AddAssetEventsResOperationsAssetsItemAssigneeDto';
import type { VehicleFuelType } from './VehicleFuelType';

export type AddAssetEventsResOperationsAssetsItemDto = {
    /**
     * type of the asset
     */
    type: AddAssetEventsResOperationsAssetsItemDto.type;
    /**
     * id of the asset in database
     */
    id: string;
    /**
     * Team member assigned to use the asset
     */
    assignee?: AddAssetEventsResOperationsAssetsItemAssigneeDto;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    fuel_type: VehicleFuelType;
};

export namespace AddAssetEventsResOperationsAssetsItemDto {

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
