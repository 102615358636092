import { array, object, string } from 'yup';

const schema = object({
    name: string()
        .required('Route name is a required field')
        .max(50, 'Route name must be less than 50 characters'),
    beginningLocation: string().required(
        'Beginning of Route is a required field'
    ),
    endingLocation: string().when('repeatLocation', {
        is: true,
        otherwise: scheme =>
            scheme.required('End of Route is a required field'),
    }),
    stops: array().of(object()).notInEdit('Route item(s) are in edit mode'),
});

export default schema;
