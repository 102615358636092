/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUserResDto } from '../models/GetUserResDto';
import type { RegisterUsersReqDto } from '../models/RegisterUsersReqDto';
import type { RegisterUsersResDto } from '../models/RegisterUsersResDto';
import type { UpdateUserReqDto } from '../models/UpdateUserReqDto';
import type { UpdateUserResDto } from '../models/UpdateUserResDto';
import type { UploadAvatarUsersReqDto } from '../models/UploadAvatarUsersReqDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Register New User
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns RegisterUsersResDto Successful Response
     * @throws ApiError
     */
    public static registerUsers(
authorization: string,
requestBody: RegisterUsersReqDto,
): CancelablePromise<RegisterUsersResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/register',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get authenticated user
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetUserResDto Successful Response
     * @throws ApiError
     */
    public static getUser(
authorization: string,
): CancelablePromise<GetUserResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/users/me',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update the User
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateUserResDto Successful Response
     * @throws ApiError
     */
    public static updateUser(
authorization: string,
requestBody: UpdateUserReqDto,
): CancelablePromise<UpdateUserResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/users/me',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upload avatar
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateUserResDto Successful Response
     * @throws ApiError
     */
    public static uploadAvatarUsers(
authorization: string,
requestBody: UploadAvatarUsersReqDto,
): CancelablePromise<UpdateUserResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/users/me/upload_avatar',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
