import {
    DiscountType,
    GetOrderResFinancialsDto,
    GetOrdersResItemFinancialsDto,
    OrderStatus as OrderStatuses,
    PricingType,
    DirectSource,
    PaymentStatus,
} from '../../requests';
import { Customer } from '../customer/customer.types';
import { Booking } from '../booking/booking.types';
import { Activity } from '../common.types';

export { OrderStatuses, PaymentStatus, DirectSource };

export enum SourceType {
    DIRECT = 'direct',
    REFERRAL = 'referral',
}

export interface PriceFlat {
    count: number;
    unitPrice: number;
    base?: number;
}

export interface PriceProduct {
    type: PricingType.PER_PRODUCT;
    count: number;
    unitPrice: number;
    base?: number;
}

export interface PriceTicket {
    type: PricingType.PER_TICKET;
    count: number;
    unitPrice: number;
    base?: number;
}

export interface PriceAgeBand {
    type: PricingType.PER_AGEBAND;
    base?: number;
    infants?: PriceFlat;
    children?: PriceFlat;
    youth?: PriceFlat;
    adults?: PriceFlat;
    seniors?: PriceFlat;
}

export type Price = PriceAgeBand | PriceProduct | PriceTicket;

export interface PriceSummary {
    currency: string;
    main: Price;
    options?: {
        name: string;
        type: string;
        price: Price;
    }[];
    beforeDiscount: number;
    discount?: {
        amount: number;
        type: DiscountType;
    };
    final: number;
}

export interface OrderItem {
    id: string;
    number: string;
    participants: {
        customer: Pick<Customer, 'id' | 'fullName'>;
    };
    financials?: GetOrdersResItemFinancialsDto;
    status: OrderStatuses;
    bookingsCount: number;
    productsCount: number;
    travelersCount: number;
    reason?: string;
    lifecycle: Activity[];
}

export interface Order {
    id: string;
    number: string;
    participants: {
        customer: {
            id: string;
            fullName: string;
        };
    };
    financials?: GetOrderResFinancialsDto;
    status: OrderStatuses;
    bookings: Booking[];
    lifecycle: Activity[];
}
