import {
    InfiniteData,
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useIsChanged } from '@travelity/web/src/hooks';
import { formatValue } from '@travelity/form';
import {
    BookingsService,
    BookingStatus,
    ComputePriceBookingsReqDto,
    ComputePriceBookingsResFinancialsPriceDto,
    CreateBookingReqSource0Dto,
    CreateBookingReqSource1Dto,
    CreateEventReqOperationsDto,
    DirectSource,
    DiscountType,
    EventsService,
    GetOrderResBookingsItemDto,
    GetOrderResDto,
    GetOrdersResDto,
    GetOrdersResItemDto,
    OrdersService,
    ProductOptionType,
    ProductType,
    UpdateOrderResDto,
} from '../../requests';
import { AvailableEvent } from '../availability/availability.types';
import {
    useComputePriceBookings,
    useGetOrder,
    useGetOrders,
    useGetOrdersLazy,
} from '../../queries';
import {
    getOrderDtoToOrder,
    getOrderItemDtoToOrder,
    PriceDtoToPriceSummary,
} from './order.converters';
import { PriceSummary, SourceType } from './order.types';
import { Customer } from '../customer/customer.types';
import { customerToOrderCustomerDto } from '../customer/customer.converters';

import { Referral } from '../team/team.types';

export enum RequestTypes {
    EVENT = 'event',
    ORDER = 'order',
}

export const useCreateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.createBooking> | undefined
            >,
            unknown,
            {
                customer: Customer;
                productOptions: { name: string; type: ProductOptionType }[];
                event: AvailableEvent;
                price: ComputePriceBookingsResFinancialsPriceDto;
                isDraft?: boolean;
                notes: string[];
                source: {
                    type: SourceType;
                    name?: DirectSource;
                    referral?: Referral;
                };
            }
        >,
        'mutationFn'
    >,
    callbacks?: {
        onOrderSuccess?: (
            o: GetOrderResDto,
            params: {
                customer: Customer;
                productOptions: { name: string; type: ProductOptionType }[];
                event: AvailableEvent;
                price: ComputePriceBookingsResFinancialsPriceDto;
                isDraft?: boolean;
                notes: string[];
            }
        ) => void;
        onEventSuccess?: () => void;
    }
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    const { onOrderSuccess, onEventSuccess } = callbacks || {};
    return useMutation(async params => {
        const {
            customer,
            event,
            price,
            isDraft,
            productOptions,
            notes,
            source,
        } = params;
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        const openOrders = await OrdersService.getOrders(
            authorization,
            customer.id
        );
        let openOrder;
        if (openOrders.items?.length) {
            [openOrder] = openOrders.items;
        } else {
            const order = {
                participants: {
                    customer: customerToOrderCustomerDto(customer),
                },
            };
            try {
                openOrder = await OrdersService.createOrder(
                    authorization,
                    // @ts-ignore
                    order
                );
                onOrderSuccess?.(openOrder, params);
            } catch (e) {
                throw new Error(RequestTypes.ORDER);
            }
        }

        const booking = event.rawDto.bookings_summary?.bookings[0];
        if (!booking) throw new Error();
        let eventId = event.id;
        if (booking?.product.type !== ProductType.GROUP_TOUR || !event.id) {
            if (booking) {
                try {
                    const newEvent = await EventsService.createEvent(
                        authorization,
                        {
                            date: event.rawDto.date,
                            operations: event.rawDto
                                .operations as CreateEventReqOperationsDto,
                            product: booking.product,
                            status: event.rawDto.status,
                        }
                    );
                    eventId = newEvent.id;
                    onEventSuccess?.();
                } catch (e) {
                    throw new Error(RequestTypes.EVENT);
                }
            }
        }

        const newBooking = await BookingsService.createBooking(authorization, {
            event: {
                id: eventId,
            },
            product: {
                id: booking.product.id,
                financials: {
                    pricing:
                        event?.rawDto.bookings_summary?.bookings[0]?.product
                            .financials.pricing || {},
                },
                options: productOptions,
            },
            order: {
                id: openOrder.id,
            },
            participants: {
                pax: booking?.participants.pax,
            },
            source:
                source.type === SourceType.DIRECT
                    ? {
                          type: CreateBookingReqSource0Dto.type.DIRECT,
                          name: source.name as DirectSource,
                      }
                    : {
                          type: CreateBookingReqSource1Dto.type.REFERRAL,
                          id: source.referral?.id,
                          name: source.referral?.name,
                          email: source.referral?.email as string,
                          company: source.referral?.company,
                      },
            // date: booking.date,
            financials: { price },
            notes,
            status: isDraft ? BookingStatus.DRAFT : BookingStatus.HOLD,
        });

        queryClient.invalidateQueries({
            queryKey: ['OrdersServiceGetOrders', { pageSize: 1000 }],
            exact: true,
            refetchType: 'active',
        });

        return newBooking;
    }, options);
};

// export const useDebouncedPrice = (
//     params: Partial<ComputePriceBookingsReqDto>,
//     debounce: number = 1000
// ) => {
//     const [state, setState] = useState<{
//         data?: PriceSummary;
//         rawData?: ComputePriceBookingsResFinancialsPriceDto;
//         isLoading: boolean;
//     }>({ isLoading: true });
//     const { mutate: getPrices } = useComputePriceBookings({
//         onSuccess: data => {
//             setState({
//                 data: PriceDtoToPriceSummary(data.financials.price),
//                 rawData: data.financials.price,
//                 isLoading: false,
//             });
//         },
//     });
//
//     React.useEffect(() => {
//         setState({ isLoading: true });
//         const handler = setTimeout(() => {
//             if (params.participants?.pax && params.product) {
//                 getPrices({
//                     requestBody: params as ComputePriceBookingsReqDto,
//                 });
//             }
//         }, debounce);
//
//         return () => {
//             clearTimeout(handler);
//         };
//     }, [params, debounce]);
//
//     return {
//         data: state.data,
//         rawData: state.rawData,
//         isLoading: state.isLoading,
//     };
// };

export const useUpdatePrice = (
    params: Partial<ComputePriceBookingsReqDto>,
    initialPrice?: ComputePriceBookingsResFinancialsPriceDto
) => {
    const [state, setState] = useState<{
        data?: PriceSummary;
        rawData?: ComputePriceBookingsResFinancialsPriceDto;
        isLoading: boolean;
        discount: number;
        discountType: DiscountType;
        options: string[];
    }>({
        isLoading: true,
        discount: 0,
        discountType: DiscountType.RELATIVE,
        options: [],
    });
    const { mutate: getPrices } = useComputePriceBookings({
        onSuccess: data => {
            setState(prevData => ({
                ...prevData,
                data: PriceDtoToPriceSummary(data.financials.price),
                rawData: data.financials.price,
                isLoading: false,
            }));
        },
    });

    const needsUpdate = useMemo(() => {
        const paramsDiscount = params.financials?.price?.discount?.amount || 0;
        const paramsDiscountType =
            params.financials?.price?.discount?.type || DiscountType.RELATIVE;
        const paramsOptions =
            params.product?.options?.map(
                o =>
                    `${o.name}:${
                        o.participants?.pax
                            ? formatValue(o.participants?.pax)
                            : ''
                    }`
            ) || [];

        return (
            paramsDiscountType !== state.discountType ||
            paramsDiscount !== state.discount ||
            paramsOptions.join(',') !== state.options.join(',')
        );
    }, [state.discount, state.discountType, state.options, params]);

    const initialPriceChanged = useIsChanged(initialPrice);
    const paramsChanged = useIsChanged(params);
    React.useEffect(() => {
        if (initialPriceChanged && initialPrice) {
            setState({
                data: PriceDtoToPriceSummary(initialPrice),
                rawData: initialPrice,
                isLoading: false,
                discount: 0,
                discountType: DiscountType.RELATIVE,
                options: [],
            });
        }
    }, [params, initialPrice, initialPriceChanged, paramsChanged]);

    const update = useCallback(() => {
        if (params.participants?.pax && params.product) {
            setState({
                isLoading: true,
                discount: params.financials?.price?.discount?.amount || 0,
                discountType:
                    params.financials?.price?.discount?.type ||
                    DiscountType.RELATIVE,
                options:
                    params.product.options?.map(
                        o =>
                            `${o.name}:${
                                o.participants?.pax
                                    ? formatValue(o.participants?.pax)
                                    : ''
                            }`
                    ) || [],
            });
            getPrices({
                requestBody: params as ComputePriceBookingsReqDto,
            });
        }
    }, [params, getPrices]);

    return {
        data: state.data,
        rawData: state.rawData,
        isLoading: state.isLoading,
        changed: needsUpdate,
        update,
    };
};

type UseGetOrdersParamTypes = Parameters<typeof useGetOrders>;
export const useOrders = (params: UseGetOrdersParamTypes[0] = {}) => {
    const newParams = { ...params, pageSize: 1000 };
    const queryKey = ['OrdersServiceGetOrders', newParams];
    const { data, ...other } = useGetOrders(newParams, [newParams]);

    const queryClient = useQueryClient();
    const update = (orderId: string, order: UpdateOrderResDto) => {
        // @ts-ignore
        queryClient.setQueryData<GetOrdersResDto>(queryKey, orders => {
            if (!orders?.items) return undefined;
            const items = orders.items.map(o =>
                o.id === orderId ? { ...o, ...order } : o
            );
            return { ...orders, items };
        });
    };

    const parsedData = useMemo(
        () => data?.items?.map(getOrderItemDtoToOrder),
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

type UseGetOrdersLazyParamTypes = Parameters<typeof useGetOrdersLazy>;
export const useOrdersLazy = (
    params: UseGetOrdersLazyParamTypes[0] = {},
    options: UseGetOrdersLazyParamTypes[2] = {}
) => {
    const queryKey = ['OrdersServiceGetOrdersLazy', params];
    const { data, ...other } = useGetOrdersLazy(params, queryKey, options);

    const queryClient = useQueryClient();
    const update = (orderId: string, order: UpdateOrderResDto) => {
        queryClient.setQueryData<InfiniteData<GetOrdersResDto>>(
            ['OrdersServiceGetOrders', ...queryKey],
            orders => {
                if (!orders?.pages) return undefined;
                const pages = orders.pages.map(page => {
                    const items =
                        page.items?.map(ord =>
                            ord.id === orderId
                                ? ({ ...ord, ...order } as GetOrdersResItemDto)
                                : ord
                        ) || [];
                    return { ...orders, items };
                });
                return { ...orders, pages };
            }
        );
    };

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getOrderItemDtoToOrder)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

export const useOrder = (orderId?: string) => {
    const queryKey = ['OrdersServiceGetOrder', orderId];
    const { data, ...other } = useGetOrder(
        useMemo(() => ({ orderId: orderId as string }), [orderId]),
        queryKey,
        { enabled: !!orderId }
    );

    const parsedData = useMemo(
        () => (data ? getOrderDtoToOrder(data) : data),
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

export const useUpdateOrderBooking = () => {
    const queryClient = useQueryClient();
    const queriesData = queryClient.getQueriesData({
        queryKey: ['OrdersServiceGetOrder'],
        exact: false,
    });

    const update = (
        bookingId: string,
        callback: (b: GetOrderResBookingsItemDto) => GetOrderResBookingsItemDto
    ) => {
        queriesData.forEach(([queryKey]) => {
            queryClient.setQueryData<GetOrderResDto>(
                queryKey,
                // @ts-ignore
                order => {
                    if (!order) return undefined;
                    const bookings =
                        order.bookings?.map(b =>
                            b.id === bookingId ? callback(b) : b
                        ) || [];
                    return {
                        ...order,
                        bookings,
                    };
                }
            );
        });
    };

    return update;
};

export const useExport = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.exportDetailsOrders>>,
            unknown,
            {
                id: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        async ({ id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.exportDetailsOrders(id, authorization);
        },
        {
            onSuccess: data => {
                window.open(data.url, '_blank')?.focus();
            },
            ...options,
        }
    );
};
