import { User } from '@travelity/api';

export const getLastCompletedStep = (user?: User) => {
    if (!user) {
        return 0;
    }

    if (user.concurrency) {
        return 8;
    }
    if (user.weekdays) {
        return 7;
    }
    if (user.workload) {
        return 6;
    }
    if (user.occupation) {
        return 5;
    }
    if (user.timeZone) {
        return 4;
    }
    if (user.currency) {
        return 3;
    }
    if (user.languages) {
        return 2;
    }
    if (user.fullName) {
        return 1;
    }

    return 0;
};
