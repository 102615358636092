import { useAuth0 } from '@auth0/auth0-react';
import { useMutation } from '@tanstack/react-query';
import { CustomMutationOptions } from '../common.types';
import {
    AddTravelersBookingsReqTravelersItemDto,
    BookingsService,
    GetProductsResItem0RouteDto,
    TimezoneName,
    type UpdateTravelersBookingsReqTravelersItemDto,
} from '../../requests';
import { useBooking } from '../booking/booking.hooks';
import { TravelerPickUp } from './traveler.types';
import { useUpdateEventSilent } from '../event/event.hooks';

export const useAddTraveler = (
    options?: CustomMutationOptions<{
        bookingId: string;
        traveler: AddTravelersBookingsReqTravelersItemDto;
    }>,
    updateBooking?: boolean
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { mutateAsync: getBooking } = useBooking();

    return useMutation(async ({ bookingId, traveler }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        const response = await BookingsService.addTravelersBookings(
            bookingId,
            authorization,
            {
                travelers: [traveler],
            }
        );
        if (updateBooking) {
            await getBooking({ id: bookingId });
        }
        return response;
    }, options);
};

export const useUpdateTraveler = (
    options?: CustomMutationOptions<{
        bookingId: string;
        traveler: UpdateTravelersBookingsReqTravelersItemDto;
        pickUp?: TravelerPickUp;
        oldPickUp?: TravelerPickUp;
    }>,
    updateBooking?: boolean
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { mutateAsync: getBooking } = useBooking();
    const { mutateAsync: updatePickUp } = useUpdatePickUp();

    return useMutation(async ({ bookingId, traveler, pickUp, oldPickUp }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        const response = await BookingsService.updateTravelersBookings(
            bookingId,
            authorization,
            {
                travelers: [traveler],
            }
        );

        await updatePickUp({
            bookingId,
            travelerId: traveler.id,
            pickUp,
            oldPickUp,
        });

        if (updateBooking) {
            await getBooking({ id: bookingId });
        }
        return response;
    }, options);
};

export const useRemoveTraveler = (
    options?: CustomMutationOptions<{
        bookingId: string;
        travelerId: string;
    }>,
    updateBooking?: boolean
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { mutateAsync: getBooking } = useBooking();

    return useMutation(async ({ bookingId, travelerId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        const response = await BookingsService.removeTravelersBookings(
            bookingId,
            authorization,
            {
                travelers: [{ id: travelerId }],
            }
        );
        if (updateBooking) {
            await getBooking({ id: bookingId });
        }
        return response;
    }, options);
};

export const useUpdatePickUp = (
    options?: CustomMutationOptions<
        {
            bookingId: string;
            travelerId: string;
            pickUp?: TravelerPickUp;
            oldPickUp?: TravelerPickUp;
        },
        any
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const update = useUpdateEventSilent();

    return useMutation({
        mutationFn: async ({ bookingId, travelerId, pickUp, oldPickUp }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            if (pickUp && (!oldPickUp || pickUp.id !== oldPickUp.id)) {
                return BookingsService.setTravelerPickupInfoBookings(
                    bookingId,
                    travelerId,
                    authorization,
                    {
                        date: {
                            tz_name: Intl.DateTimeFormat().resolvedOptions()
                                .timeZone as TimezoneName,
                            start: Math.round(pickUp.time.getTime() / 1000),
                        },
                        id: pickUp.id as string,
                        location: {
                            name: pickUp.name,
                        },
                    }
                );
            }
            if (!pickUp && oldPickUp) {
                return BookingsService.unsetTravelerPickupInfoBookings(
                    bookingId,
                    travelerId,
                    authorization
                );
            }

            return null;
        },
        ...options,
        onSuccess: (data, ...other) => {
            console.log('data', data);
            if (data?.event) {
                update(data.event.id, ev => ({
                    ...ev,
                    operations: {
                        ...ev.operations,
                        route: data.event.route as GetProductsResItem0RouteDto,
                    },
                }));
            }
            options?.onSuccess?.(data, ...other);
        },
    });
};
