import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';

export interface LoadingOverlayProps {
    title?: string;
    subTitle?: string;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = props => {
    const { title, subTitle } = props;

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            gap={1}
            sx={{
                zIndex: 1000,
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: 'rgba(255, 255, 255, 0.8)',
            }}
        >
            <CircularProgress color="secondary" />
            <Typography
                sx={{
                    color: '#2B395B',
                    fontSize: '14px',
                    fontWeight: '600',
                }}
            >
                {title}
            </Typography>
            <Typography
                sx={{
                    color: '#2B395B',
                    fontSize: '12px',
                    fontWeight: '400',
                }}
            >
                {subTitle}
            </Typography>
        </Stack>
    );
};
