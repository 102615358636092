import React, { useCallback, useEffect } from 'react';
import {
    User,
    userDetailsToUserDetailsDto,
    useUpdateUser,
    useUser,
} from '@travelity/api';
import { Box, Divider, InputAdornment, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import {
    Switch,
    TextField,
    useForm,
    WeekdayPicker,
    Weekdays,
} from '@travelity/form';
import {
    Card,
    IconButton,
    IconButtonGroup,
    LoadingOverlay,
} from '@travelity/ui';
import { PageTitle } from '../../../components/page-title';
import WeekdayRow from '../../register-steps/components/weekday-row';
import { workloadSchema, workweekSchema } from './settings.schema';
import SelectTimezone from '../../../components/select-timezone/select-timezone';

function Settings() {
    const { data, isLoading } = useUser();

    const { mutate: update, isLoading: isSaving } = useUpdateUser({});

    // Card 0
    const [form0Editing, setForm0Editing] = React.useState(false);
    const {
        Form: Form0,
        submit: submit0,
        reset: reset0,
        formState: { isSubmitting: isSubmitting0, isSubmitted: isSubmitted0 },
    } = useForm({
        onSubmit: useCallback((user: Partial<User>) => {
            update(
                {
                    requestBody: userDetailsToUserDetailsDto(user),
                },
                {
                    onSuccess: () => setForm0Editing(false),
                }
            );
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset0({
                timeZone: data.timeZone,
            });
        }
    }, [form0Editing, reset0, data]);

    // Card 1
    const [form1Editing, setForm1Editing] = React.useState(false);
    const {
        Form: Form1,
        submit: submit1,
        reset: reset1,
        formState: { isSubmitting: isSubmitting1, isSubmitted: isSubmitted1 },
    } = useForm({
        onSubmit: useCallback((user: Partial<User>) => {
            update(
                {
                    requestBody: userDetailsToUserDetailsDto(user),
                },
                {
                    onSuccess: () => setForm1Editing(false),
                }
            );
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset1({
                concurrencyValue: data.concurrencyValue,
                concurrencyAuto: data.concurrencyAuto,
            });
        }
    }, [form1Editing, reset1, data]);

    // Card 2
    const [form2Editing, setForm2Editing] = React.useState(false);
    const {
        Form: Form2,
        submit: submit2,
        reset: reset2,
        watch: watch2,
        formState: { isSubmitting: isSubmitting2, isSubmitted: isSubmitted2 },
    } = useForm({
        schema: workloadSchema,
        onSubmit: useCallback((user: Partial<User>) => {
            update(
                {
                    requestBody: userDetailsToUserDetailsDto(user),
                },
                {
                    onSuccess: () => setForm2Editing(false),
                }
            );
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset2({
                maxEventsUnlimited: data.maxEventsUnlimited,
                maxEvents: data.maxEvents,
                maxHoursFullDay: data.maxHoursFullDay,
                maxHours: data.maxHours,
            });
        }
    }, [form2Editing, reset2, data]);

    // Card 3
    const [form3Editing, setForm3Editing] = React.useState(false);
    const {
        Form: Form3,
        submit: submit3,
        reset: reset3,
        watch: watch3,
        getValues: getValues3,
        setValue: setValue3,
        formState: { isSubmitting: isSubmitting3, isSubmitted: isSubmitted3 },
    } = useForm({
        schema: workweekSchema,
        onSubmit: useCallback((user: Partial<User>) => {
            update(
                {
                    requestBody: userDetailsToUserDetailsDto(user),
                },
                {
                    onSuccess: () => setForm3Editing(false),
                }
            );
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset3({
                weekdays: data.weekdays,
                weekdayHours: data.weekdayHours,
            });
        }
    }, [form3Editing, reset3, data]);

    const weekdays = watch3('weekdays');
    const weekdayHours = watch3('weekdayHours');
    useEffect(() => {
        if (weekdays) {
            const values = getValues3();
            const currentWeekdayHours = values.weekdayHours;
            const weekdayHoursNew: Partial<{
                [v in Weekdays]: {
                    start: number;
                    end: number;
                }[];
            }> = {};

            weekdays.forEach(w => {
                weekdayHoursNew[w] = currentWeekdayHours?.[w] || [
                    {
                        start: 0,
                        end: 1439,
                    },
                ];
            });

            setValue3('weekdayHours', weekdayHoursNew);
        }
    }, [weekdays]);

    return isLoading ? null : (
        <>
            <PageTitle sx={{ justifyContent: 'center' }}>
                Vendibility Settings
            </PageTitle>
            <Stack gap={5} alignItems="center" sx={{ mb: 1 }}>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Time zone
                    </Typography>
                    <Form0>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form0Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm0Editing(true)
                                            }
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit0()}
                                                disabled={isSaving}
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm0Editing(false)
                                                }
                                                disabled={isSaving}
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            {isSaving && (isSubmitting0 || isSubmitted0) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}{' '}
                            <Stack direction="row" alignItems="center">
                                <SelectTimezone
                                    name="timeZone"
                                    label=""
                                    disabled={!form0Editing}
                                    placeholder="Select time zone"
                                />
                            </Stack>
                        </Card>
                    </Form0>
                </Stack>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Concurrency
                    </Typography>
                    <Form1>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form1Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm1Editing(true)
                                            }
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit1()}
                                                disabled={isSaving}
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm1Editing(false)
                                                }
                                                disabled={isSaving}
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            {isSaving && (isSubmitting1 || isSubmitted1) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}{' '}
                            <Stack direction="row" alignItems="center">
                                <Stack
                                    direction="row"
                                    sx={{
                                        width: 0.5,
                                        pr: 3,
                                        height: '28px',
                                        borderRight: '1px solid #DFE1ED',
                                    }}
                                >
                                    <Switch
                                        name="concurrencyValue"
                                        label="Concurrency"
                                        disabledWithValue={!form1Editing}
                                        LabelProps={{
                                            sx: {
                                                color: '#2B395B',
                                                pl: 1,
                                            },
                                        }}
                                    />
                                </Stack>
                                <Box
                                    sx={{
                                        pl: 3,
                                        width: 0.5,
                                    }}
                                >
                                    <Switch
                                        name="concurrencyAuto"
                                        label="Auto"
                                        disabledWithValue={!form1Editing}
                                        LabelProps={{
                                            sx: {
                                                color: '#2B395B',
                                                pl: 1,
                                            },
                                        }}
                                    />
                                </Box>
                            </Stack>
                        </Card>
                    </Form1>
                </Stack>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Workload
                    </Typography>
                    <Form2>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form2Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm2Editing(true)
                                            }
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit2()}
                                                disabled={isSaving}
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm2Editing(false)
                                                }
                                                disabled={isSaving}
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            <Stack>
                                <Typography sx={{ color: '#CACEDC' }}>
                                    Max Events
                                </Typography>
                                <Stack direction="row" alignItems="center">
                                    <Stack
                                        direction="row"
                                        sx={{
                                            width: 0.5,
                                            pr: 3,
                                            height: '28px',
                                            borderRight: '1px solid #DFE1ED',
                                        }}
                                    >
                                        <Switch
                                            name="maxEventsUnlimited"
                                            label="Unlimited"
                                            disabledWithValue={!form2Editing}
                                            LabelProps={{
                                                sx: {
                                                    color: '#2B395B',
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            pl: 3,
                                            width: 0.5,
                                        }}
                                    >
                                        <TextField
                                            name="maxEvents"
                                            label=""
                                            placeholder=""
                                            sx={{ my: 2 }}
                                            disabled={
                                                watch2('maxEventsUnlimited') ||
                                                !form2Editing
                                            }
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Box
                                                            sx={{
                                                                color: '#2B395B',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            EVENTS/DAY
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            regExp={/^(\d+)?$/}
                                        />
                                    </Box>
                                </Stack>
                                <Typography sx={{ color: '#CACEDC' }}>
                                    Max Booked Hours
                                </Typography>
                                <Stack direction="row" alignItems="center">
                                    <Stack
                                        direction="row"
                                        sx={{
                                            width: 0.5,
                                            pr: 3,
                                            height: '28px',
                                            borderRight: '1px solid #DFE1ED',
                                        }}
                                    >
                                        <Switch
                                            name="maxHoursFullDay"
                                            label="Full Day"
                                            disabledWithValue={!form2Editing}
                                            LabelProps={{
                                                sx: {
                                                    color: '#2B395B',
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Box
                                        sx={{
                                            pl: 3,
                                            width: 0.5,
                                        }}
                                    >
                                        <TextField
                                            name="maxHours"
                                            label=""
                                            placeholder=""
                                            sx={{ my: 2 }}
                                            disabled={
                                                watch2('maxHoursFullDay') ||
                                                !form2Editing
                                            }
                                            fullWidth
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Box
                                                            sx={{
                                                                color: '#2B395B',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            HOURS/DAY
                                                        </Box>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            regExp={/^(\d+)?$/}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>
                            {isSaving && (isSubmitting2 || isSubmitted2) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}
                        </Card>
                    </Form2>
                </Stack>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Workweek
                    </Typography>
                    <Form3>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form3Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm3Editing(true)
                                            }
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit3()}
                                                disabled={isSaving}
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm3Editing(false)
                                                }
                                                disabled={isSaving}
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            <Box
                                sx={{
                                    opacity: form3Editing ? 1 : 0.6,
                                    pointerEvents: form3Editing
                                        ? 'all'
                                        : 'none',
                                }}
                            >
                                <WeekdayPicker
                                    name="weekdays"
                                    chipSx={{
                                        py: '12px',
                                        px: '16px',
                                        borderRadius: '26px',
                                        background: '#F4F6FA',
                                        border: '1px solid transparent',
                                        '&.MuiChip-colorPrimary': {
                                            border: '1px solid #55B5CF',
                                            background: '#DDF0F5',
                                            color: '#2B395B',
                                        },
                                    }}
                                />
                                <Divider sx={{ mx: 10, my: 3 }} />
                                {weekdayHours &&
                                    Object.keys(weekdayHours).map(w => (
                                        <WeekdayRow
                                            key={w}
                                            name={`weekdayHours.${w}`}
                                            weekday={w as Weekdays}
                                        />
                                    ))}
                            </Box>
                            {isSaving && (isSubmitting3 || isSubmitted3) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}
                        </Card>
                    </Form3>
                </Stack>
            </Stack>
        </>
    );
}

export default Settings;
