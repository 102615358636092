import React, { useCallback, useEffect } from 'react';
import {
    User,
    userDetailsToUserDetailsDto,
    useUpdateUser,
    useUser,
} from '@travelity/api';
import { Divider, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import { Select, useForm } from '@travelity/form';
import {
    Card,
    IconButton,
    IconButtonGroup,
    LoadingOverlay,
} from '@travelity/ui';
import { PageTitle } from '../../../components/page-title';
import { useCurrencyOptions } from '../../../hooks';

function Settings() {
    const { data, isLoading } = useUser();

    const { mutate: update, isLoading: isSaving } = useUpdateUser({});

    // Card 1
    const [form1Editing, setForm1Editing] = React.useState(false);
    const {
        Form: Form1,
        submit: submit1,
        reset: reset1,
        formState: { isSubmitting: isSubmitting1, isSubmitted: isSubmitted1 },
    } = useForm({
        onSubmit: useCallback((user: Partial<User>) => {
            update(
                {
                    requestBody: userDetailsToUserDetailsDto(user),
                },
                {
                    onSuccess: () => setForm1Editing(false),
                }
            );
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset1({
                currency: data.currency,
            });
        }
    }, [form1Editing, reset1, data]);

    const currencyOptions = useCurrencyOptions();

    return isLoading ? null : (
        <>
            <PageTitle sx={{ justifyContent: 'center' }}>
                Financial Settings
            </PageTitle>
            <Stack gap={5} alignItems="center" sx={{ mb: 1 }}>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Currency
                    </Typography>
                    <Form1>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form1Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm1Editing(true)
                                            }
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit1()}
                                                disabled={isSaving}
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm1Editing(false)
                                                }
                                                disabled={isSaving}
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            {isSaving && (isSubmitting1 || isSubmitted1) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}
                            <Stack direction="row" alignItems="center">
                                <Select
                                    placeholder="Select base currency"
                                    name="currency"
                                    label=""
                                    disabled={!form1Editing}
                                    options={currencyOptions}
                                />
                            </Stack>
                        </Card>
                    </Form1>
                </Stack>
            </Stack>
        </>
    );
}

export default Settings;
