/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetBookingResFinancialsPriceMainPrice0Dto = {
    /**
     * Cost or price, when type is per-ticket or per-product.
     */
    unit_amount: number;
    /**
     * Count of travelers of the booking
     */
    pax_count: number;
    /**
     * Indicates which pricing model is used.
     */
    type: GetBookingResFinancialsPriceMainPrice0Dto.type;
    /**
     * Unconditional base price/cost
     */
    base_amount?: number;
};

export namespace GetBookingResFinancialsPriceMainPrice0Dto {

    /**
     * Indicates which pricing model is used.
     */
    export enum type {
        PER_TICKET = 'per-ticket',
        PER_PRODUCT = 'per-product',
    }


}
