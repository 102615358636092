import React, { ReactNode } from 'react';
import { Box, ButtonProps, Divider, Paper, Theme } from '@mui/material';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { usePopupState } from 'material-ui-popup-state/hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { SystemStyleObject } from '@mui/system';

import { IconTextButton } from '../icon-text-button';

export interface CardItemOption {
    label: string;

    [k: string]: any;
}

export interface CardListContainerProps extends Partial<ButtonProps> {
    children?: ReactNode;
    readonly?: boolean;
    items?: number;
    maxHeight?: number;
    noPlaceholder?: boolean;
    sx?: SystemStyleObject<Theme>;
    disableAdding?: boolean;
    addButtonText?: string;
    onAdd?: (initialState?: Record<string, any>) => void;
    addOptions?: CardItemOption[];
}

export const CardListContainer: React.FC<CardListContainerProps> = props => {
    const {
        children,
        readonly,
        items = 0,
        maxHeight,
        noPlaceholder,
        sx,
        disableAdding,
        addButtonText = 'Add',
        onAdd,
        addOptions,
    } = props;
    // Handle this with disable adding
    // const hasAnyEdit = items.some(item => item.isEdit || item.isNew);

    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'demoMenu',
    });

    const showAddButton =
        !disableAdding && !readonly && (noPlaceholder || items > 0);

    return (
        <Box sx={{ maxHeight, position: 'relative', '&': sx }}>
            <Box
                component={maxHeight ? PerfectScrollbar : 'div'}
                sx={{ maxHeight, '.ps__rail-y': { opacity: 0.6 } }}
            >
                <Paper
                    sx={{
                        p: 2,
                        height: noPlaceholder || items > 0 ? 'auto' : '244px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        ...(showAddButton &&
                            items > 0 && {
                                pb: '60px',
                            }),
                        ...(readonly
                            ? {
                                  opacity: 0.6,
                                  pointerEvents: 'none',
                              }
                            : {}),
                        ...(readonly && !items
                            ? {
                                  opacity: 0,
                              }
                            : {}),
                    }}
                >
                    {children}
                    {!disableAdding &&
                        !readonly &&
                        !noPlaceholder &&
                        items === 0 && (
                            <IconTextButton
                                text={addButtonText}
                                icon={<Add />}
                                className="add-button"
                                data-testid="add-card"
                                {...(addOptions
                                    ? bindTrigger(popupState)
                                    : {
                                          onClick: () => onAdd?.(),
                                      })}
                            />
                        )}

                    <Menu
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        MenuListProps={{
                            sx: { width: popupState.anchorEl?.clientWidth },
                        }}
                        {...bindMenu(popupState)}
                    >
                        {addOptions?.map(({ label, ...rest }, index) => (
                            <>
                                {!!index && (
                                    <Divider
                                        sx={{ mx: 2, '&&&': { my: 0.5 } }}
                                    />
                                )}
                                <MenuItem
                                    sx={{
                                        justifyContent: 'center',
                                        color: '#2B395B',
                                        fontSize: '12px',
                                        margin: '0 16px',
                                        borderRadius: '4px',
                                        '&:before': {
                                            borderTop: index
                                                ? '1px solid #DFE1ED'
                                                : 0,
                                        },
                                        '&:hover': {
                                            backgroundColor: '#D7E7EB',
                                        },
                                    }}
                                    onClick={() => {
                                        popupState.close();
                                        onAdd?.(rest);
                                    }}
                                >
                                    {label}
                                </MenuItem>
                            </>
                        ))}
                    </Menu>
                </Paper>
            </Box>
            {showAddButton && (
                <Box
                    sx={{
                        background: 'rgba(246, 246, 251, 0.75)',
                        pl: 1,
                        pr: 0.25,
                        py: 1.25,
                        width: 'calc(100% - 22px)',
                        position: 'absolute',
                        bottom: 0,
                        left: '8px',
                        ...(noPlaceholder && items === 0
                            ? {
                                  width: '100%',
                                  top: 0,
                                  p: 0,
                                  left: 0,
                                  background: 'transparent',
                              }
                            : {}),
                    }}
                >
                    <Button
                        sx={{
                            bgcolor: '#FFFFFF',
                        }}
                        variant="outlined"
                        className="add-button"
                        fullWidth
                        {...(addOptions
                            ? bindTrigger(popupState)
                            : {
                                  onClick: () => onAdd?.(),
                              })}
                        data-testid="add-card"
                    >
                        <Add /> {addButtonText}
                    </Button>
                </Box>
            )}
        </Box>
    );
};
