import { useMemo } from 'react';
import {
    InfiniteData,
    UseMutationOptions,
    useQueryClient,
} from '@tanstack/react-query';
import {
    useCreateProduct,
    useGetProducts1,
    useGetProductsLazy,
} from '../../queries';
import {
    getProduct1DtoToProduct1,
    getProductDtoToProduct,
} from './product.converters';
import {
    CreateProductReqDto,
    GetProductsResDto,
    ProductsService,
    UpdateProductResDto,
} from '../../requests';

export { useCreateProduct };

type UseGetProductParamTypes = Parameters<typeof useGetProductsLazy>;
export const useProductsLazy = (
    params: UseGetProductParamTypes[0] = {},
    options: UseGetProductParamTypes[2] = {}
) => {
    const queryKey = ['ProductsServiceGetProducts', params];
    const { data, ...other } = useGetProductsLazy(params, [params], options);

    const queryClient = useQueryClient();
    const update = (productId: string, product: UpdateProductResDto) => {
        queryClient.setQueryData<InfiniteData<GetProductsResDto>>(
            queryKey,
            // @ts-ignore
            products => {
                if (!products?.pages) return undefined;
                const pages = products.pages.map(page => {
                    const items =
                        page.items?.map(prd =>
                            prd.id === productId ? { ...prd, ...product } : prd
                        ) || [];
                    return { ...products, items };
                });
                return { ...products, pages };
            }
        );
    };

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getProductDtoToProduct)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        update,
        data: parsedData,
        ...other,
    };
};

type UseGetProduct1ParamTypes = Parameters<typeof useGetProducts1>;
export const useProducts = (
    params: UseGetProduct1ParamTypes[0] = {},
    options: UseGetProduct1ParamTypes[2] = {}
) => {
    const queryKey = ['GetProducts', params];
    const { data, ...other } = useGetProducts1(
        { ...params, pageSize: 1000 },
        [params],
        {
            staleTime: 30 * 60 * 1000,
            cacheTime: 5 * 60 * 1000,
            ...options,
        }
    );

    return {
        data: data?.items.map(getProduct1DtoToProduct1),
        ...other,
    };
};

export const useAddProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof ProductsService.createProduct> | undefined
            >,
            unknown,
            {
                requestBody: CreateProductReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();
    return useCreateProduct({
        ...options,
        onSuccess: (...arg) => {
            queryClient.invalidateQueries({
                queryKey: ['ProductsServiceGetProducts'],
                exact: false,
            });
            options?.onSuccess?.(...arg);
        },
    });
};
