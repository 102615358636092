/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum StaffMemberPosition {
    OPERATOR = 'operator',
    DRIVER = 'driver',
    GUIDE = 'guide',
}
