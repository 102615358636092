import React, { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { MembershipStatus } from '@travelity/api';

export interface MemberStatusIconProps {
    status: MembershipStatus;
}

const statusIcons = {
    [MembershipStatus.ACTIVE]: CheckCircleIcon,
    [MembershipStatus.PENDING]: AccessTimeIcon,
    [MembershipStatus.INACTIVE]: CancelIcon,
};

export const MemberStatusIcon: FC<MemberStatusIconProps> = ({ status }) => {
    const Icon = statusIcons[status];

    return (
        <Icon
            sx={{
                color: '#6B748C',
                fontSize: '24px',
            }}
        />
    );
};
