/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAssetEventsReqDto } from '../models/AddAssetEventsReqDto';
import type { AddAssetEventsResDto } from '../models/AddAssetEventsResDto';
import type { AssignStaffMemberEventsReqDto } from '../models/AssignStaffMemberEventsReqDto';
import type { AssignStaffMemberEventsResDto } from '../models/AssignStaffMemberEventsResDto';
import type { CancelEventsReqDto } from '../models/CancelEventsReqDto';
import type { CancelEventsResDto } from '../models/CancelEventsResDto';
import type { CreateEventReqDto } from '../models/CreateEventReqDto';
import type { CreateEventResDto } from '../models/CreateEventResDto';
import type { GetEventsResDto } from '../models/GetEventsResDto';
import type { RemoveAssetEventsResDto } from '../models/RemoveAssetEventsResDto';
import type { RemoveStaffMemberEventsResDto } from '../models/RemoveStaffMemberEventsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * List/Filter Events
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param productId ID of the product
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetEventsResDto Successful Response
     * @throws ApiError
     */
    public static getEvents(
authorization: string,
startTs?: number,
endTs?: number,
productId?: Array<string>,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetEventsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events',
            headers: {
                'authorization': authorization,
            },
            query: {
                'start_ts': startTs,
                'end_ts': endTs,
                'product_id': productId,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Event
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createEvent(
authorization: string,
requestBody: CreateEventReqDto,
): CancelablePromise<CreateEventResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Cancel Event
     * @param eventId ID of the event being canceled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelEvents(
eventId: string,
authorization: string,
requestBody: CancelEventsReqDto,
): CancelablePromise<CancelEventsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/cancel',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add staff member to Event
     * @param eventId ID of the event being canceled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static assignStaffMemberEvents(
eventId: string,
authorization: string,
requestBody: AssignStaffMemberEventsReqDto,
): CancelablePromise<AssignStaffMemberEventsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/assign_staff_member',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove staff member to Event
     * @param eventId ID of the event being canceled
     * @param email Email of the member to be removed
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeStaffMemberEvents(
eventId: string,
email: string,
authorization: string,
): CancelablePromise<RemoveStaffMemberEventsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/remove_staff_member',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            query: {
                'email': email,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add asset to Event
     * @param eventId ID of the event being canceled
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static addAssetEvents(
eventId: string,
authorization: string,
requestBody: AddAssetEventsReqDto,
): CancelablePromise<AddAssetEventsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/add_asset',
            path: {
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove asset from Event
     * @param eventId ID of the event being updated
     * @param assetId ID of the asset being removed
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static removeAssetEvents(
eventId: string,
assetId: string,
authorization: string,
): CancelablePromise<RemoveAssetEventsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{event_id}/remove_asset/{asset_id}',
            path: {
                'event_id': eventId,
                'asset_id': assetId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
