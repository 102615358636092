/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetTeamsResItemMembersItemDto } from './GetTeamsResItemMembersItemDto';
import type { VehicleFuelType } from './VehicleFuelType';

export type GetEventsResItem0OperationsAssetsItemDto = {
    /**
     * type of the asset
     */
    type: GetEventsResItem0OperationsAssetsItemDto.type;
    /**
     * id of the asset in database
     */
    id: string;
    /**
     * Team member assigned to use the asset
     */
    assignee?: GetTeamsResItemMembersItemDto;
    /**
     * Make of the asset
     */
    make: string;
    /**
     * Model of the asset
     */
    model: string;
    /**
     * year of the asset
     */
    year: number;
    /**
     * license plate of the asset
     */
    license_plate: string;
    /**
     * Max passenger count
     */
    max_passengers: number;
    /**
     * Max passenger count
     */
    fuel_type: VehicleFuelType;
};

export namespace GetEventsResItem0OperationsAssetsItemDto {

    /**
     * type of the asset
     */
    export enum type {
        VEHICLE = 'vehicle',
    }


}
