import React from 'react';
import { Box, Stack } from '@mui/material';
import { TextField, CardList, TagDropdown } from '@travelity/form';
import { number, object, string } from 'yup';
import { Tag } from '@travelity/ui';
import { PricingType } from '@travelity/api';
import { OptionCardContent } from './components/option-card';

export interface ProductFinancingFormProps {
    preview?: boolean;
    showFull?: boolean;
    currency: string;
}

const optionTypeOptions = [
    { value: 'service', label: 'Service' },
    { value: 'refreshment', label: 'Refreshment' },
    { value: 'breakfast', label: 'Breakfast' },
    { value: 'lunch', label: 'Lunch' },
    { value: 'dinner', label: 'Dinner' },
    { value: 'overnight_stay', label: 'Overnight' },
];

export const getOptionLabel = (value: string): string =>
    optionTypeOptions.find(o => o.value === value)?.label || value;

const schema = object({
    name: string().required('Name is a required field').uniqName(),
    description: string().required('Description is a required field'),
    price: object().when(['type', 'hasPricing'], ([type, hasPricing], sch) => {
        if (!hasPricing) return sch;
        if (type === PricingType.PER_AGEBAND) {
            return sch.shape({
                counts: object().when('.', ([counts], s) => {
                    return Object.values(counts).find(v => v)
                        ? s
                        : s.shape({
                              infants: number()
                                  .required(
                                      'At least one ageband price required'
                                  )
                                  .min(
                                      1,
                                      'At least one ageband price required'
                                  ),
                          });
                }),
            });
        }
        if (type === PricingType.PER_TICKET) {
            return sch.shape({
                perPerson: number()
                    .required('Price per person is required')
                    .min(1, 'Price per person is required'),
            });
        }
        return sch.shape({
            perProduct: number()
                .required('Price per product is required')
                .min(1, 'Price per product is required'),
        });
    }),
});

const ProductFinancingForm: React.FC<ProductFinancingFormProps> = props => {
    const { preview, currency, showFull } = props;

    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <CardList
                disabled={preview}
                noEmptyState={preview}
                formParams={{
                    defaultValues: { optionType: 'service' },
                    schema,
                }}
                headerSx={{ height: 'auto' }}
                defaultState={{ optionType: 'service' }}
                maxHeight={showFull ? undefined : 500}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <Stack direction="row" sx={{ width: 1 }}>
                            <TextField
                                sx={{ mx: '10px' }}
                                autoFocus
                                InputProps={{
                                    sx: { fontSize: '14px', pl: 1.5 },
                                }}
                                placeholder="Type name"
                                name="name"
                                variant="standard"
                                showErrors
                                helperText=""
                                fullWidth
                            />
                            <TagDropdown
                                label="Type"
                                name="optionType"
                                options={optionTypeOptions}
                            />
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            sx={{ width: 1 }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box component="span">{item.name}</Box>
                            <Tag
                                label="Type"
                                value={
                                    item.optionType
                                        ? getOptionLabel(
                                              item.optionType as string
                                          )
                                        : undefined
                                }
                            />
                        </Stack>
                    )
                }
                renderContent={({ item, editing }) => (
                    <OptionCardContent
                        item={item}
                        editing={editing}
                        currency={currency as string}
                    />
                )}
                addButtonText="Add Product Option"
                name="items"
            />
        </Stack>
    );
};

export default React.memo(ProductFinancingForm);
