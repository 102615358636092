import React, { useCallback, useEffect } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import {
    CurrencyField,
    DatePicker,
    PaxPicker,
    SearchSelect,
    SearchSelectOption,
    Select,
    StaticDatePicker,
    useForm,
    SubmitButton,
    TextField,
} from '@travelity/form';
import { useFormState, useWatch } from 'react-hook-form';
import { startOfToday } from 'date-fns';
import {
    FilterDateValue,
    FilterForm,
    FilterOption,
    FilterTypes,
    FilterValue,
} from '../../filters.types';
import { useCurrencyOptions } from '../../../../hooks';
import { SelectItemProduct } from '../../../select-item-product';

export interface FilterSettingsProps {
    filter: FilterOption;
    value?: FilterValue;
    onCancel?: () => void;
    onRemove?: () => void;
    onUpdate: (v: any) => void;
}

const today = startOfToday();

export const FilterSettings: React.FC<FilterSettingsProps> = props => {
    const { filter, value, onCancel, onRemove, onUpdate } = props;

    const { Form, control, watch, setValue } = useForm<FilterForm>({
        onSubmit: useCallback(
            (data: FilterForm) => {
                onUpdate(data.value);
            },
            [onUpdate]
        ),
        mode: 'onChange',
        defaultValues: {
            value,
        },
    });
    const currencyOptions = useCurrencyOptions();
    const formState = useFormState({ control });

    const formValue = watch('value');
    const closeOnChange = filter.type === FilterTypes.DATE;
    useEffect(() => {
        if (closeOnChange && formState.isDirty) onUpdate(formValue);
    }, [formValue, closeOnChange, formState.isDirty]);

    const currentValue = useWatch({ name: 'value', control });
    const hasValue =
        filter.type === FilterTypes.DROPDOWN
            ? !!(currentValue as string[])?.length
            : !!currentValue;

    useEffect(() => {
        if (
            filter.type === FilterTypes.DATES &&
            currentValue &&
            (currentValue as FilterDateValue).startDate &&
            (currentValue as FilterDateValue).endDate
        ) {
            const { startDate, endDate } = currentValue as FilterDateValue;
            if (startDate > endDate) {
                setValue('value.endDate', startDate);
            }
        }
    }, [filter, currentValue]);

    const disableBefore = filter.preventPast ? today : undefined;

    return (
        <Form>
            <Stack sx={{ width: 370 }}>
                <Divider>{filter.label}</Divider>
                <Box sx={{ fontSize: '13px', mt: 1, color: '#949BAC' }}>
                    {filter.selectText}
                </Box>
                {filter.type === FilterTypes.PAX && <PaxPicker name="value" />}
                {filter.type === FilterTypes.DROPDOWN && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <SearchSelect
                            name="value"
                            label={filter.label}
                            multiple={filter.multiple}
                            options={filter.options as SearchSelectOption[]}
                            placeholder={`Select ${filter.label}`}
                            MenuItem={
                                filter.name === 'products'
                                    ? SelectItemProduct
                                    : undefined
                            }
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.SEARCH && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <TextField
                            name="value"
                            label=""
                            showErrors
                            placeholder={`Type ${filter.label}`}
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.DATES && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <DatePicker
                            name="value.startDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'Start Date',
                            }}
                            disableBefore={disableBefore}
                        />
                        <DatePicker
                            name="value.endDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'End Date',
                            }}
                            disableBefore={
                                (currentValue as FilterDateValue)?.startDate ||
                                disableBefore
                            }
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.DATE && (
                    <StaticDatePicker name="value" label="" showErrors />
                )}
                {filter.type === FilterTypes.PRICE && (
                    <Stack direction="column" sx={{ mt: 2 }} gap="16px">
                        <Select
                            width="100%"
                            name="value.currency"
                            label="Currency"
                            placeholder="Select Currency"
                            options={currencyOptions}
                        />
                        <CurrencyField
                            fullWidth
                            label="Min Price"
                            placeholder="Type Min Price"
                            name="value.minPrice"
                            currency={watch('value.currency') as string}
                        />
                        <CurrencyField
                            fullWidth
                            label="Max Price"
                            placeholder="Type Max Price"
                            name="value.maxPrice"
                            currency={watch('value.currency') as string}
                        />
                    </Stack>
                )}
                {filter.type === FilterTypes.TAGS && (
                    <Stack direction="row" sx={{ mt: 2 }} gap="6px">
                        <DatePicker
                            name="value.startDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'Start Date',
                            }}
                        />
                        <DatePicker
                            name="value.endDate"
                            label=""
                            showErrors
                            TextFieldProps={{
                                helperText: '',
                                size: 'small',
                                placeholder: 'End Date',
                            }}
                        />
                    </Stack>
                )}

                {!closeOnChange && (
                    <Stack
                        direction="row"
                        gap={1}
                        sx={{ mt: 2 }}
                        alignItems="center"
                    >
                        {onCancel && (
                            <Button
                                variant="contained"
                                color="neutral"
                                sx={{ flexGrow: 1 }}
                                onClick={() => onCancel()}
                            >
                                Back
                            </Button>
                        )}
                        {onRemove && (
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    textAlign: 'center',
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        textDecoration: 'underline',
                                        cursor: filter.required
                                            ? 'default'
                                            : 'pointer',
                                        pt: 1,
                                        color: filter.required
                                            ? '#9198AA'
                                            : '#2B395B', // : '#9198AA',
                                        fontSize: '14px',
                                    }}
                                    onClick={() =>
                                        !filter.required && onRemove()
                                    }
                                >
                                    Remove filter
                                </Box>
                            </Box>
                        )}
                        <SubmitButton
                            variant="contained"
                            color="secondary"
                            sx={{ flexGrow: 1 }}
                            type="submit"
                            disabled={!formState.isDirty || !hasValue}
                        >
                            Add
                        </SubmitButton>
                    </Stack>
                )}
            </Stack>
        </Form>
    );
};
