import React, { useCallback, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    SearchSelect,
    CurrencyField,
    DatePicker,
    PaxPicker,
    Select,
    useForm,
} from '@travelity/form';
import { Box, Divider, Stack } from '@mui/material';
import { useProducts, AvailabilityFilterValues } from '@travelity/api';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';
import { Button } from '@travelity/ui';
import { startOfToday } from 'date-fns';
import { useWatch } from 'react-hook-form';
import schema from './booking-modal.schema';
import { useCurrencyOptions, useSelectOptions } from '../../hooks';
import { SelectItemProduct } from '../select-item-product';
import { productTypeOptions } from '../../views/select-type/select-type';

const formId = 'booking-modal';

export interface BookingModalProps {
    handleCancel: () => void;
    // handleConfirm: () => void;
    open: boolean;
}

const today = startOfToday();

const BookingModal: React.FC<BookingModalProps> = ({ handleCancel, open }) => {
    const navigate = useNavigate();

    const {
        Form,
        trigger,
        setValue,
        reset,
        formState: { errors },
        watch,
        control,
    } = useForm<AvailabilityFilterValues>({
        schema,
        mode: 'onChange',
        reValidateMode: 'onChange',
        onSubmit: useCallback(
            (data: AvailabilityFilterValues) => {
                navigate('/availability', {
                    state: {
                        pax: data.pax,
                        dates: data.dates,
                        pricing:
                            data.pricing?.minPrice || data.pricing?.maxPrice
                                ? data.pricing
                                : undefined,
                        products: data.products?.length
                            ? data.products
                            : undefined,
                        productTypes: data.productTypes?.length
                            ? data.productTypes
                            : undefined,
                    },
                });
                handleCancel();
            },
            [navigate]
        ),
    });

    useEffect(() => {
        reset({});
        if (open) trigger();
    }, [open, reset]);

    const currencyOptions = useCurrencyOptions();

    const { data: products } = useProducts();
    const productList = useSelectOptions(products, 'name', 'id', true);

    // const productId = watch('product');
    // const productOptions = useMemo(() => {
    //     if (productId && products) {
    //         return products.find(({ id }) => productId === id)?.options?.items;
    //     }
    //     return undefined;
    // }, [products, productId]);
    // const optionList = useSelectOptions(productOptions, 'name', 'name', true);

    const errorFields = [];
    if (errors.pax?.message) errorFields.push(errors.pax.message);
    if (errors.dates?.startDate?.message)
        errorFields.push(errors.dates.startDate.message);
    if (errors.dates?.endDate?.message)
        errorFields.push(errors.dates.endDate.message);

    const dates = useWatch({ name: 'dates', control });
    useEffect(() => {
        if (dates?.startDate && dates?.endDate) {
            const { startDate, endDate } = dates;
            if (startDate > endDate) {
                setValue('dates.endDate', startDate);
            }
        }
    }, [dates]);

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            PaperProps={{ sx: { py: 1, bgcolor: '#fff' } }}
        >
            <DialogTitle
                sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    fontWeight: 600,
                    color: '#2B395B',
                }}
                id="alert-dialog-title"
            >
                Create Booking
            </DialogTitle>
            <DialogContent
                sx={{
                    overflow: 'hidden',
                    pr: 1,
                    '& > div': {
                        pr: 2,
                        maxHeight: 'calc(100vh - 212px)',
                    },
                }}
            >
                <PerfectScrollbar>
                    <Form id={formId}>
                        <Box
                            sx={{
                                color: '#C0C4CE',
                                fontSize: '21px',
                                fontWeight: 600,
                            }}
                        >
                            Select Product Availability Filters
                        </Box>

                        <Stack
                            direction="column"
                            gap={2}
                            sx={{ pt: 2, width: '388px' }}
                        >
                            <Divider>Basic Filters</Divider>

                            <Stack direction="row" gap="6px">
                                <DatePicker
                                    name="dates.startDate"
                                    label="Start Date*"
                                    TextFieldProps={{
                                        helperText: '',
                                        placeholder: 'Select Start Date',
                                    }}
                                    iconTooltip="Select start date"
                                    disableBefore={today}
                                />
                                <DatePicker
                                    name="dates.endDate"
                                    label="End Date*"
                                    TextFieldProps={{
                                        helperText: '',
                                        placeholder: 'Select End Date',
                                    }}
                                    iconTooltip="Select end date"
                                    disableBefore={dates?.startDate || today}
                                />
                            </Stack>
                            <PaxPicker
                                name="pax"
                                withDropdown
                                selectProps={{
                                    label: 'PAX*',
                                    placeholder: 'Select PAX',
                                }}
                            />

                            <Divider>Product Filters</Divider>
                            <SearchSelect
                                name="productTypes"
                                label="Product Type"
                                multiple
                                options={productTypeOptions}
                                placeholder="Select Product Types"
                            />
                            <SearchSelect
                                name="products"
                                label="Product"
                                multiple
                                options={productList}
                                placeholder="Select Products"
                                MenuItem={SelectItemProduct}
                            />
                            {/* <SearchSelect */}
                            {/*    name="options" */}
                            {/*    label="Product Option" */}
                            {/*    options={optionList} */}
                            {/*    placeholder="Select Product Option" */}
                            {/*    MenuItem={SelectItemProductOption} */}
                            {/* /> */}

                            <Divider>Pricing Filters</Divider>

                            <Select
                                name="pricing.currency"
                                label="Currency"
                                placeholder="Select Currency"
                                options={currencyOptions}
                            />
                            <Stack direction="row" gap="6px">
                                <CurrencyField
                                    label="Min Price"
                                    placeholder="Min Price"
                                    name="pricing.minPrice"
                                    currency={
                                        watch('pricing.currency') as string
                                    }
                                />
                                <CurrencyField
                                    label="Max Price"
                                    placeholder="Max Price"
                                    name="pricing.maxPrice"
                                    currency={
                                        watch('pricing.currency') as string
                                    }
                                />
                            </Stack>
                        </Stack>
                    </Form>
                </PerfectScrollbar>
            </DialogContent>
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button
                    color="neutral"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{
                        px: '26px',
                        py: '10px',
                    }}
                >
                    Cancel
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    form={formId}
                    sx={{
                        flexGrow: 2,
                        py: '10px',
                    }}
                    requiredFields={errorFields}
                >
                    Check Availability
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BookingModal;
