import React, { useEffect } from 'react';
import { Box, Divider, Stack, Tooltip } from '@mui/material';
import AccessTime from '@mui/icons-material/AccessTime';
import {
    TextField,
    DurationField,
    ToggleButton,
    CardList,
    requiredDuration,
} from '@travelity/form';
import { useFormContext } from 'react-hook-form';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { durationToString } from '@travelity/utils';
import { ReactComponent as RotateLocation } from '../../assets/rotate-location.svg';

export interface ProductRouteFormProps {
    preview?: boolean;
    showFull?: boolean;
}

const schema = object({
    name: string().required('Name is a required field').uniqName(),
    arrivalOffset: requiredDuration,
});

const ProductRouteForm: React.FC<ProductRouteFormProps> = ({
    preview,
    showFull,
}) => {
    const { t } = useTranslation();
    const { watch, trigger } = useFormContext();

    useEffect(() => {
        trigger('endingLocation');
    }, [watch('repeatLocation')]);

    return (
        <Stack spacing={2} sx={{ width: '453px' }}>
            <TextField
                placeholder="Type route name"
                name="name"
                label="Route Name"
                disabled={preview}
            />
            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Beginning of Route
            </Divider>
            <TextField
                placeholder="Type Beginning Location"
                name="beginningLocation"
                label="Location"
                disabled={preview}
            />
            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                End of Route
            </Divider>
            <Stack direction="row" gap={1}>
                {!watch('repeatLocation') ? (
                    <TextField
                        placeholder="Type End Location"
                        name="endingLocation"
                        label="Location"
                        disabled={preview}
                    />
                ) : (
                    <TextField
                        placeholder="Type End Location"
                        name="endingLocation"
                        value={watch('beginningLocation')}
                        label="Location"
                        disabled
                        // InputProps={{ readOnly: true }}
                    />
                )}
                <Tooltip
                    title={t('Round Trip')}
                    enterDelay={100}
                    enterNextDelay={0}
                >
                    <ToggleButton name="repeatLocation" disabled={preview}>
                        <RotateLocation />
                    </ToggleButton>
                </Tooltip>
            </Stack>
            <Divider sx={{ maxWidth: '388px' }} textAlign="left">
                Stops
            </Divider>

            <CardList
                formParams={{ schema }}
                disabled={preview}
                maxHeight={showFull ? undefined : 356}
                renderHeader={({ editing, item }) =>
                    editing ? (
                        <TextField
                            sx={{ mx: '10px' }}
                            autoFocus
                            InputProps={{
                                sx: { fontSize: '14px', pl: 1.5 },
                            }}
                            placeholder="Type location name"
                            name="name"
                            variant="standard"
                            showErrors
                            helperText=""
                            fullWidth
                        />
                    ) : (
                        <Box component="span">{item.name}</Box>
                    )
                }
                renderContent={({ item, editing }) => (
                    <Stack
                        sx={{
                            p: 1,
                        }}
                        direction="row"
                        gap={1}
                    >
                        <Box
                            sx={{
                                width: '36px',
                                height: '36px',
                                color: 'primary.contrastText',
                                bgcolor: 'primary.main',
                                borderRadius: '12px',
                                p: '6px',
                                mb: 1,
                                ml: 1,
                                mt: editing ? 2 : 0.5,
                            }}
                        >
                            <AccessTime />
                        </Box>
                        <Stack flexGrow={2} sx={{ p: 0.5, pr: 1 }} gap={0.5}>
                            {!editing && (
                                <>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Arrival Offset
                                        </Box>
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#2B395B',
                                            }}
                                        >
                                            {durationToString(
                                                item.arrivalOffset
                                            )}
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Stop Duration
                                        </Box>
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#2B395B',
                                            }}
                                        >
                                            {durationToString(item.duration)}
                                        </Box>
                                    </Stack>
                                </>
                            )}
                            {editing && (
                                <>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Arrival Offset
                                        </Box>
                                        <Box>
                                            <DurationField
                                                name="arrivalOffset"
                                                showErrors
                                            />
                                        </Box>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{
                                                fontSize: '12px',
                                                color: '#a5a9b8',
                                            }}
                                        >
                                            Stop Duration
                                        </Box>
                                        <Box>
                                            <DurationField name="duration" />
                                        </Box>
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    </Stack>
                )}
                addButtonText="Add Stop"
                name="stops"
            />
        </Stack>
    );
};

export default React.memo(ProductRouteForm);
