import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@travelity/form';
import { Box } from '@mui/material';
import { ProductFinancingForm } from '../../../components/product-financing-form';
import schema from '../../../components/product-financing-form/product-financing-form.schema';
import StepContent from './step-content';
import { useStepperContext } from '../../../components/stepper/stepper.provider';
import { AddProductFormState } from '../add-product.types';
import { useHelperContext } from '../../../contexts/helper-context';

export interface FinancingStepProps {}

const FinancingStep: React.FC<FinancingStepProps> = () => {
    const { state, next } = useStepperContext<AddProductFormState>();
    const onSubmit = useCallback((data: Record<string, any>) => {
        next({ ...state, financial: data });
    }, []);
    const { Form, formState, watch } = useForm({
        onSubmit,
        schema,
        mode: 'onChange',
        context: {
            capacities: state.capacity?.capacities,
        },
        validateInitially: true,
        defaultValues: state.financial,
    });
    const errors = useMemo(() => {
        return Object.values(formState.errors)
            .map(error => error?.message)
            .filter(v => v) as string[];
    }, [formState]);

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo();
    }, [setInfo]);

    useEffect(() => {
        const callback = () => {
            setInfo(
                <>
                    <Box sx={{ fontWeight: 'bold' }}>Pricing</Box>
                    Travelity enables you to have multiple pricing options with
                    different unit prices/costs as well as unconditional base
                    price. You can also list the items/services included in the
                    price by providing values for Inclusions field.
                </>
            );
        };
        const el = document.querySelector('.add-button');
        el?.addEventListener('click', callback);
        return () => el?.removeEventListener('focus', callback);
    }, []);

    const adaptivePricing = watch('adaptivePricing');
    useEffect(() => {
        setInfo(
            <>
                <Box sx={{ fontWeight: 'bold' }}>Tiered Pricing</Box>
                If you have already provided multiple capacity options, you can
                attach pricings to each of them by switching the Tiered pricing
                toggle.
            </>
        );
    }, [adaptivePricing]);

    return (
        <StepContent title="Pricing and Financials" errors={errors}>
            <Form id="step">
                <ProductFinancingForm
                    capacity={state.capacity as Record<string, any>}
                />
            </Form>
        </StepContent>
    );
};

export default React.memo(FinancingStep);
