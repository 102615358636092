/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum DirectSource {
    WALK_IN = 'walk_in',
    EMAIL = 'email',
    PHONE_CALL = 'phone_call',
}
