import { array, object, string } from 'yup';

const schema = object({
    currency: string().required('Currency is a required field'),
    items: array()
        .of(object())
        .notInEdit('Finish editing of a stop')
        .when(
            ['adaptivePricing', '$capacities'],
            ([adaptivePricing, capacities], sch) => {
                if (adaptivePricing) {
                    return sch.min(
                        capacities?.length,
                        'You need to have pricing for all capacities'
                    );
                }
                return sch.min(1, 'Pricing is a required item');
            }
        ),
    paymentMethods: array().min(1, 'Payment method is required'),
});

export default schema;
